<template>
    <v-app id="inspire" >
      <vue-header ></vue-header>
      <v-main style="padding:0px!important;">
        <v-container :style="this.$vuetify.breakpoint.xs ?'margin-top:80px;margin-bottom: 72px;':'margin-top:150px;margin-bottom: 72px;'">
       <div style=" justify-content: space-around;height: 100%;" class="mt-5 mb-1">

         <v-row class="" style="margin: 0px;">
         <v-col lg="10" cols="12" class="mx-auto my-auto ">
           <v-card class="shadow border-radius-xl ">
            <v-overlay
                      :absolute="true"
                      :value="overlay"
                      :opacity=".8"
                    >
                    <v-progress-circular
                        indeterminate
                        color="green"
                      ></v-progress-circular>
                    </v-overlay>
            <v-stepper
              alt-labels
              elevation="0"
              v-model="currentStep"
              class="bg-transparent overflow-visible step-survey"
            >
              <div class="pt-1">
                <v-stepper-header>
                  <template>
                    <span class="mb-0 mt-1 text-sm text-body font-weight-light float-right survey-count mr-2">
                      Showing {{ currentStep }} of {{ steps.length }}
                    </span>
                  </template>
                </v-stepper-header>
              </div>
              <v-stepper-items class="border-radius-xl overflow-hidden mt-5" >
                <v-stepper-content
                  v-for="(step, index) in steps"
                  :key="index"
                  :step="index + 1"
                  class="bg-white border-radius-xl px-7 pt-2"
                  
                >
                  <v-card v-if="currentStep == index + 1">
                    <div>
                      <v-form :ref="'form-'+index" v-model="formValid" lazy-validation>
                        <v-row class="mt-2" >
                          <v-col cols="12" :md="field.md" class="py-0" v-for="(field, fieldIndex) in step.fields" :key="fieldIndex">
                            <h5 v-if="field.type== 'h5'" :class="field.class">{{ field.label }}</h5>
                            <p v-if="field.type== 'p'" :class="field.class" v-html="field.label"></p>
                            <v-text-field
                              v-if="field.type=='text'"
                              :label="field.label"
                              v-model="formData[field.name]"
                              :color="field.color || '#4CAF50'"
                              :rules="field.required ? rules : []"
                              :required="field.required || false"
                              class="font-size-input input-style"
                            ></v-text-field>
                            <v-text-field
                              v-if="field.type=='email'"
                              :label="field.label"
                              v-model="formData[field.name]"
                              :color="field.color || '#4CAF50'"
                              :rules="field.required ? emailRules :[]"
                              :required="field.required || false"
                              class="font-size-input input-style"
                            ></v-text-field>
                            <v-text-field
                              v-if="field.type=='tel'"
                              :label="field.label"
                              v-model="formData[field.name]"
                              :color="field.color || '#4CAF50'"
                              v-mask="'(###) ###-####'"
                              :rules="field.required ? rules : []"
                              :required="field.required || false"
                              class="font-size-input input-style"
                            ></v-text-field>
                            <v-checkbox v-model="formData[field.name]" color="#4CAF50" required :rules="field.required ? rules : []" v-if="field.type=='checkbox'">
                            <template v-slot:label>
                              <div>
                                I agree to
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <a
                                      target="_blank"
                                      href="#"
                                      @click.stop
                                      v-on="on"
                                      style="color:#188bf6"
                                    >
                                    terms & conditions
                                    </a>
                                  </template>
                                  Opens in new window
                                </v-tooltip>
                                provided by the company. By providing my phone number, I agree to receive text messages from the business.
                              </div>
                            </template>
                          </v-checkbox>
                          <div v-if="field.type=='radio'">{{field.label}}</div>
                          <v-radio-group row v-model="formData[field.name]"  v-if="field.type=='radio'" :rules="field.required ? rules : []">
                          <!-- <template v-slot:label>
                            <div>{{field.label}}</div>
                          </template> -->
                          <v-radio :value="option" v-for="(option, index_op) in field.options" :key="index_op" color="#4CAF50">
                            <template v-slot:label>
                              <div>{{option}}</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                    <div class="d-flex">
                      <v-btn
                        v-if="index > 0"
                        :ripple="false"
                        :elevation="0"
                        class="font-weight-bold placeholder-lighter text-xs text-dark btn-outline-secondary bg-transparent py-5 px-6 mt-6 mb-2 ms-2"
                        @click="prevStep"
                      >
                        Prev
                      </v-btn>
                      <v-btn
                       v-if="!hover"
                        :ripple="false"
                        :elevation="0"
                        class="font-weight-bold text-xs btn-default py-5 px-6 mt-6 mb-2 me-2 ms-auto"
                        :color="'#4CAF50'"
                        @click="validateForm(index)"
                        :loading="index === steps.length - 1 && loadingSaveButton"
                      >
                        {{ index === steps.length - 1 ? 'Submit' : 'Next' }}
                      </v-btn>
                      
                    </div>
                    <div class="d-flex"><span class="mb-0 mt-1 text-sm text-body" style="color: red!important;"><strong>Note:</strong> All fields are auto saved. You can leave any time and continue later. </span></div>
                  
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
            <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
            >
            <stripe-checkout
              ref="checkoutRef"
              :pk="publishableKey"
              :session-id="sessionId"
            />
              <v-btn color="#4CAF50" @click="submitStripe()">Pay to generate your Pass Profile</v-btn>
            </v-overlay>
          </v-fade-transition>
           </v-card>
          
           
         </v-col>
       </v-row>
       
       </div>
       </v-container>
       </v-main>
       <vue-footer ></vue-footer>
    </v-app>
   </template>
   
   <script>
   import DateDropdown from 'vue-date-dropdown'
   import VueRecaptcha from 'vue-recaptcha'
   import { steps } from './SurveySteps.js';
   import moment from 'moment'
   import { debounce } from 'lodash';
   import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
   import { StripeCheckout } from '@vue-stripe/vue-stripe';
   // import Vue from 'vue'
   export default {
       components: {
           DateDropdown,
           'vue-recaptcha' : VueRecaptcha,
           'RotateLoader': ClipLoader,
           StripeCheckout
       },
       data()  {
           return {
              validGeneral : true,
              currentStep : 1,
              formValid: false,
              overlay: false,
              publishableKey :'',
              sessionId: '',
              formData: {},
              user: '',
              fieldValues: {},
              steps : steps,
              hover: false,
              loadingSaveButton : false,
              rules: [
                  value => !!value || 'Required.',
              ],
              emailRules: [
                  v => !!v || 'E-mail is required',
                  v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ],
              loadingFlag : false,
              customer : {
              full_name : "",
              company_name:"",
              email: "",
              phone:"",
              is_agreed : 0,
              },
                  
           }
       },
       created () {
          
          this.getUserProfile();
          this.generateSession();
          this.getSurveyQuestions();
       },
        watch: {
         formData: {
             handler: function(newValue) {
               localStorage.setItem('survey-user', JSON.stringify(newValue));
              
             },
             deep: true
         }
        },
       beforeCreate: function () {
          //  if (localStorage.getItem('token')) {
          //      if(localStorage.getItem('is_admin'))
          //          this.$router.push('/admin/dashboard')
          //      else
          //          this.$router.push('/dashboard')
          //  }
       },
       methods : {
        nextStep() {
            this.currentStep++;
            localStorage.setItem('survey-step', this.currentStep);
            this.scrollToTop();
        },
        prevStep() {
          if (this.currentStep > 1) {
            this.currentStep--;
            localStorage.setItem('survey-step', this.currentStep);
          }
        },
        validateForm(index) {
          if(this.$refs['form-'+index][0].validate()){
              if (index === this.steps.length - 1) {
                this.submitForm();
              } else {
                this.nextStep();
              }
          }
        },
        generateSession(){
          axios({
                   method: "POST",
                   url: "/api/stripe-genearte-session",
                   data:[],
                   headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                   })
                   .then(response => {
                     this.publishableKey = response.data.pub_key;
                     this.sessionId = response.data.session_id;
                   })
        },
        submitForm(){
            this.loadingSaveButton = true;
            axios({
            method: "POST",
            url: "/api/doc-process/"+this.user.user_id,
            data:this.formData,
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate','x-api-key':process.env.VUE_APP_X_API_KEY,Authorization:"Bearer " + localStorage.getItem('token')}
            })
            .then(response => {
              console.log(response);      
              this.$router.push("/thanks");
              this.loadingSaveButton = false;
              localStorage.removeItem('survey-user');
            })
            .catch(err => {
                this.$router.push("/thanks");
                this.loadingSaveButton = false;
                localStorage.removeItem('survey-user');
            })
        },
        debouncedInput: debounce(function (name, value) {
          this.$set(this.formData, name, value);
        }, 300),
         gotoPrivacy(){
           window.open('/privacy/'+this.$route.params.url, "_blank");
         },
           getCompanyInfo(url){
              axios({
                      method: "POST",
                        
                      url: "/api/get-company-info",
                      data: {
                        url: url
                      }
                    })
                      .then(response => {
                        console.log(response.data.company);
                        this.company = response.data.company;
                        this.loadingFlag = true;
                      })
            
            },
            submitStripe () {
              // You will be redirected to Stripe's secure checkout page
              console.log(this.$refs.checkoutRef);
              //this.$refs.checkoutRef.redirectToCheckout();
              const stripe = Stripe(this.publishableKey);
              const { error } =  stripe.redirectToCheckout({

                sessionId: this.sessionId,
              });
            },
           onCaptchaVerified: function (recaptchaToken) {
           this.customer.recaptcha = recaptchaToken
           this.validateCaptcha = true
           },
           onCaptchaExpired: function () {
           this.$refs.recaptcha.reset();
           },
            getAddressData3: function (addressData3, placeResultData, id) {
               this.grant.grant_address = addressData3.name;
           },
           getAddressData2: function (addressData2, placeResultData, id) {
               this.grant.grant_business_address = addressData2.name;
           },
           getAddressData: function (addressData, placeResultData, id) {
               this.customer.address = addressData.name;
               this.customer.city = addressData.locality;
               this.customer.state = addressData.administrative_area_level_1;
               this.customer.zip = addressData.postal_code
               console.log(this.customer);
           },
           isJson(str) {
               try {
                   JSON.parse(str);
               } catch (e) {
                   return false;
               }
               return true;
           },
           verifyCrc(){
               let self = this;
               let flag = 1;
               if (!this.customer.crc_id) {
                   Vue.$toast.error('Customer ID is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }else{
                   self.verifyLoad = true;
                   axios({
                   method: "POST",
                    
                   url: "/api/auth/verify-crc",
                   data:{crc_id : self.customer.crc_id},
                   headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                   })
                   .then(response => {
                       console.log(response);
                       if(response.data.data.success == 'True'){
                           self.verifyUser = response.data.data.result;
                           self.verfiedFlag = true;
                       }else{
                           Vue.$toast.error('Invalid customer id', {
                               position: 'top-right'
                           });
                       }
                       self.verifyLoad = false
                   })
                   .catch(err => {
                       // console.log(JSON.parse(err.response.data));
                       self.verifyLoad = flase;
   
                   });
               }
           },
           getSettings() {
            let flag = 1;
            let url =process.env.VUE_APP_WASBI_URL
            var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
              axios({
                  method: "POST",
                  
                  url: "/api/get-company-settings",
                  headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    let data = response.data.data;
                    if(data){
                      this.id = data.id;
                      this.settings = data.settings;
                    }


                })
                  .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });

                  });

            },
           autofill(){
               let self = this;
               self.customer.first_name = self.verifyUser.firstname
               self.customer.last_name = self.verifyUser.lastname
               if(!self.temp_email)
                   self.customer.email = self.verifyUser.email
               self.customer.middle_name = self.verifyUser.middlename
               self.customer.phone_m = self.verifyUser.phone_mobile
               self.customer.phone_h = self.verifyUser.phone_home
               self.customer.phone_w = self.verifyUser.phone_work
               self.customer.ssn = self.verifyUser.ssno
               self.address = self.verifyUser.street_address
               if(self.verifyUser.state){
                   self.stateOptions.forEach(element => {
                       if(self.verifyUser.state.toUpperCase()  == element.name)
                           self.customer.state = element.abbreviation
                   });
               }
               self.customer.zip = self.verifyUser.zip
               self.customer.city = self.verifyUser.city
               if(self.verifyUser.birth_date){
                   var temp_dob = self.verifyUser.birth_date.split("/");
                   self.customer.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                   console.log(self.customer.dob);
               }
               
           },
           scrollToTop() {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            getUserProfile() {
            let flag = 1;
            let self = this;
            let avatar_url = process.env.VUE_APP_WASBI_URL
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                }
              axios
                .get("/api/user",header)
                .then(response => {
                    let data = response.data;

                  if (flag == 1) {
                      this.user = data.user;
                      if(this.user.credit == 0){
                        this.hover = true;
                      }
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }

                })
                  .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });

                  });

            },
            getSurveyQuestions() {
            let flag = 1;
            let self = this;
            self.overlay = true
            let avatar_url = process.env.VUE_APP_WASBI_URL
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                }
              axios
                .get("/api/get-all-questions",header)
                .then(response => {
                    let data = response.data;

                  if (flag == 1) {
                      data.data.forEach(value => {
                        value.fields = JSON.parse(value.fields);
                        this.steps.push(value)
                      });
                      if(localStorage.getItem('survey-user')){
                          this.formData = JSON.parse(localStorage.getItem('survey-user'));
                          setTimeout(function(){ self.currentStep = localStorage.getItem('survey-step') ? localStorage.getItem('survey-step') : 1;self.overlay = false }, 1000);
                          
                        }else{
                          self.formData['full_name'] = self.user.first_name+ " " + self.user.last_name
                          self.formData['email'] = self.user.email
                          self.formData['phone'] = self.user.phone_m
                          self.overlay = false;
                        }
                      
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }

                })
                  .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });

                  });

            },

           checkForm(){
               let self = this;
               let flag = 1;
               console.log(this.customer);
               
               if (!this.customer.first_name) {
                   Vue.$toast.error('First Name is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               if (!this.customer.last_name && flag == 1) {
                   Vue.$toast.error('Last Name is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               if (!this.customer.email && this.customer.is_email == false && flag == 1) {
                   Vue.$toast.error('Email is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               if (!this.customer.ssn && flag == 1) {
                   Vue.$toast.error('SSN is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               this.customer.dob = this.dob;
               if (!this.customer.dob && flag == 1) {
                   Vue.$toast.error('DOB is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
               if (reg.test(this.customer.email) == false && this.customer.is_email == false  && flag == 1) {
                   Vue.$toast.error('Invalid email format', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
   
               if (!this.customer.phone_m && this.customer.phone_m == false && flag == 1) {
                   Vue.$toast.error('Phone (Mobile) is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               
               if (!this.customer.address && this.customer.address == false  && !this.customer.city && this.customer.city == false   && !this.customer.state && this.customer.state == false  && !this.customer.zip && this.customer.zip == false  &&  flag == 1) {
                   Vue.$toast.error('Address is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
                if (!this.customer.zip && flag == 1) {
                   Vue.$toast.error('Zip Code is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               // if (!this.customer.crc_id && this.customer.crc_id == false && flag == 1) {
               //     Vue.$toast.error('CRC ID is required', {
               //         position: 'top-right'
               //     });
               //     flag = 0;
               // }
               this.customer.user_name = this.customer.email;
               // if (!this.customer.user_name && this.customer.user_name == false && flag == 1) {
               //     Vue.$toast.error('User name is required', {
               //         position: 'top-right'
               //     });
               //     flag = 0;
               // }
               if (!this.customer.password && this.customer.password == false && flag == 1) {
                   Vue.$toast.error('Password is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               if (!this.customer.confirm_password && this.customer.confirm_password == false && flag == 1) {
                   Vue.$toast.error('Confirm password is required', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               if (this.customer.password != this.customer.confirm_password && flag == 1) {
                   Vue.$toast.error('Confirm password did not match with password', {
                       position: 'top-right'
                   });
                   flag = 0;
               }
               
               if (flag == 1) {
                   this.loadingSaveButton = true;
                   let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                   this.customer.dob = this.dob;
                   this.customer.is_verified = is_verified;
                   this.customer.company_id = this.company ? this.company.id : '';
                   this.isDark = false;
                   this.isDisable = true;
                   const data = new FormData();
                   for (var key in self.customer) {
                     data.append(key,self.customer[key]);
                   }
                   data.append('file_1', this.grant.grant_business_llc);
                   data.append('file_2', this.grant.grant_business_ein);
                   data.append('grant',JSON.stringify(this.grant));
                   axios({
                   method: "POST",
                   url: "/api/auth/register",
                   data:data,
                   headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                   })
                   .then(response => {
                       console.log(response);
                           let result = response.data.data;
                           //self.$router.push('/login');
                           if(is_verified == 0)
                               Vue.$toast.success('You have successfuly registered. Please check your inbox for confirm your email', {
                               position: 'top-right'
                               }); 
                           else
                               Vue.$toast.success('You have successfuly registered.', {
                               position: 'top-right'
                               }); 
                           localStorage.setItem("temp_email", '');
                           localStorage.setItem('email_verified','')
                           localStorage.setItem('temp_pass','')
                           localStorage.setItem("token", response.data.token.token);
                           localStorage.setItem("user_id", result.user_id);
                           localStorage.setItem("email", result.email);
                           localStorage.setItem("name", result.first_name+' '+result.last_name);
                           localStorage.setItem("document_flag",false);
                           localStorage.setItem("agreement_flag",0);
                           localStorage.setItem("is_admin",0);
                           localStorage.setItem("role", JSON.stringify({name : 'client'}));
                           //   console.log(response.data);
                           if(localStorage.getItem('temp_request_id'))
                               this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
                           if(localStorage.getItem("document_flag") != 'false')
                               this.$router.push("/dashboard");
                           else
                               this.$router.push("/dashboard");
                           location.reload();
                           this.isDark = true;
                           this.loadingSaveButton = false;
                           this.isDisable = false;                    
                   })
                   .catch(err => {
                       // console.log(JSON.parse(err.response.data));
                       this.isDark = true;
                       this.isDisable = false;
                       this.loadingSaveButton = false;
                       let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                       if(data.email){
                           Vue.$toast.error(data.email[0], {
                           position: 'top-right'
                           });
                       }else if(data.password){
                           Vue.$toast.error(data.password[0], {
                           position: 'top-right'
                           });
                       }
                       else if(data.user_name){
                           Vue.$toast.error(data.user_name[0], {
                           position: 'top-right'
                           });
                       }
                       else{
                           Vue.$toast.error(data, {
                           position: 'top-right'
                           });
                       }
                       self.onCaptchaExpired()
   
                   });
   
               }
           }
       }
   }
   </script>
   
   <style>
   /* .image-logo2{
   position: absolute;
   margin: -78px -5px -4px 170px;
   } */
   .date-dropdown-container{
       height: 85px;
   }
   .date-dropdown-select{
       border: 1px solid #9E9E9E!important;
       padding: 15px!important;
   }
   .required_span{
       color:red;
   }
   .step-survey .v-stepper__step{
    padding: 5px!important;
   }
   .step-survey .v-stepper__header .v-divider{
    margin: 33px -65px 0 !important;
   }
   .survey-count{
    width: 100%;
    float:right!important;
    text-align: right;
   }
   .link{
    color:#188bf6;
   }
   .color-red{
    color: red!important;
   }
   </style>