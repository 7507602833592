<template>
  <v-app id="inspire" style="background:#F9F9F9;">
  <vue-header-admin></vue-header-admin>
      <v-main>
      <v-container fluid class="px-6 py-6">
          <v-row
              align="center"
              justify="center"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Edit Questionnaire ({{ title }})</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-row>
        <draggable class="row" v-model="fields" group="people" @start="drag=true" @end="drag=false" style="padding: 15px;">
        <v-col cols="12" :md="field.md" class="py-0" v-for="(field, fieldIndex) in fields" :key="fieldIndex" style="cursor: move;">
                            <h5 v-if="field.type== 'h5'" :class="field.class">{{ field.label }}</h5>
                            <p v-if="field.type== 'p'" :class="field.class" v-html="field.label"></p>
                            <v-text-field
                              v-if="field.type=='text'"
                              :label="field.label"
                              :color="field.color || '#4CAF50'"
                              :rules="field.required ? rules : []"
                              :required="field.required || false"
                              class="font-size-input input-style"
                            ></v-text-field>
                            <v-text-field
                              v-if="field.type=='email'"
                              :label="field.label"
                              :color="field.color || '#4CAF50'"
                              :rules="field.required ? emailRules :[]"
                              :required="field.required || false"
                              class="font-size-input input-style"
                            ></v-text-field>
                            <v-text-field
                              v-if="field.type=='tel'"
                              :label="field.label"
                              :color="field.color || '#4CAF50'"
                              v-mask="'(###) ###-####'"
                              :rules="field.required ? rules : []"
                              :required="field.required || false"
                              class="font-size-input input-style"
                            ></v-text-field>
                           
                          <div v-if="field.type=='radio'">{{field.label}}</div>
                          <v-radio-group row   v-if="field.type=='radio'" :rules="field.required ? rules : []">
                          <!-- <template v-slot:label>
                            <div>{{field.label}}</div>
                          </template> -->
                          <v-radio :value="option" v-for="(option, index_op) in field.options" :key="index_op" color="#4CAF50">
                            <template v-slot:label>
                              <div>{{option}}</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <v-btn
                              class="mb-2 mt-2"
                              dark
                              small
                              @click="dialogImage = true,selected_question = field"
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10 mr-2" dark>
                                edit
                              </v-icon>Edit
                              </v-btn>
                              <v-btn
                              class="mb-2 mt-2 ml-2"
                              dark
                              small
                              @click="fields = fields.filter(item => item !== field)"
                              color="red"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10 mr-2" dark>
                                edit
                              </v-icon>Delete
                              </v-btn>
                          </v-col>
                          </draggable>
      </v-row>

      
      <v-row>
        <v-btn
          color="#4CAF50"
          @click="saveQR"
          large
                        :loading="loadingSave"
          class="
            font-weight-bolder
            btn-default
            mb-5
            px-8
            ms-auto
          "
          
        >
          Save
        </v-btn>
        <v-btn
          color="blue"
          @click="dialogImageNew = true"
          large
          class="
            font-weight-bolder
            btn-default
            mb-5
            ml-2
          "
          
        >
          Add New
        </v-btn>
        <v-btn
          color="grey"
          large
          @click="$router.push('/admin/questionnaire')"
          class="
          ml-2
            font-weight-bolder
            btn-default
            mb-5

          "
          
        >
          Back
        </v-btn>
      </v-row>
    </div>
  </v-card>
              </v-col>
          </v-row>
          <v-dialog
            v-model="dialogDoc"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Create QR Code
              </v-card-title>
              <div  class="mt-5 mb-5">
                <Login v-if="dialogDoc" />
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogDoc = false,getUsers()"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogImage"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Edit Field
              </v-card-title>
              <v-card-text>
              <div  class="mt-5 mb-5">
                <v-row>
                  
                  <v-col cols="12">
                </v-col>
                <v-col cols="12">
               
                  <v-select
                    outlined
                    dense
                    :items="type_options"
                          v-model="selected_question.type"
                          item-text="name"
                          @change="changeType()"
                          item-value="id"
                          label="Field Type"
                    ></v-select>
                    </v-col>
                    <v-col cols="12">
               
                    <v-select
                      outlined
                      dense
                      style="margin-top: -35px;"
                      :items="field_length"
                            v-model="selected_question.md"
                            item-text="name"
                            item-value="id"
                            label="Field Length"
                      ></v-select>
                 </v-col>
                 <v-col cols="12">
                  <v-textarea
                  style="margin-top: -35px;"
                          id="name"
                          name="name"
                          outlined
                          v-model="selected_question.label"
                          label="Field Label(Display to User)"
                          ref="name"
                          />
                 </v-col>
                 <v-col cols="12" v-if="selected_question.type=='text' || selected_question.type=='radio'">
                  <v-textarea
                  style="margin-top: -35px;"
                          id="name"
                          name="name"
                          outlined
                          v-model="selected_question.name"
                          label="Field Name(Goes to AI)"
                          ref="name"
                          />
                 </v-col>
                 <v-col cols="12" v-if="selected_question.type=='radio'">
                  <div v-for="(option_radio, index_op) in selected_question.options">
                  <v-text-field

                          id="name"
                          name="name"
                          outlined
                          style="margin-top: -15px;"
                          v-model="selected_question.options[index_op]"
                          :label="'Option '+(index_op+1)"
                          ref="name"
                          />
                          </div>
                 </v-col>
                </v-row>
                            
                                 
              </div>
          
            </v-card-text>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  dark
                  :loading="loadingQuestion"
                  @click="saveQR()"
                >
                Save
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="dialogImage = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>



          <v-dialog
            v-model="dialogImageNew"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Add Field
              </v-card-title>
              <v-card-text>
              <div  class="mt-5 mb-5">
                <v-row>
                  
                  <v-col cols="12">
                </v-col>
                <v-col cols="12">
               
                  <v-select
                    outlined
                    dense
                    :items="type_options"
                          v-model="new_question.type"
                          item-text="name"
                          @change="changeTypeAdd()"
                          item-value="id"
                          label="Field Type"
                    ></v-select>
                    </v-col>
                    <v-col cols="12">
               
                    <v-select
                      outlined
                      dense
                      style="margin-top: -35px;"
                      :items="field_length"
                            v-model="new_question.md"
                            item-text="name"
                            item-value="id"
                            label="Field Length"
                      ></v-select>
                 </v-col>
                 <v-col cols="12">
                  <v-textarea
                  style="margin-top: -35px;"
                          id="name"
                          name="name"
                          outlined
                          v-model="new_question.label"
                          label="Field Label(Display to User)"
                          ref="name"
                          />
                 </v-col>
                 <v-col cols="12" v-if="new_question.type=='text' || new_question.type=='radio'">
                  <v-textarea
                  style="margin-top: -35px;"
                          id="name"
                          name="name"
                          outlined
                          v-model="new_question.name"
                          label="Field Name(Goes to AI)"
                          ref="name"
                          />
                 </v-col>
                 <v-col cols="12" v-if="new_question.type=='radio'">
                  <div v-for="(option_radio, index_op) in new_question.options">
                  <v-text-field

                          id="name"
                          name="name"
                          outlined
                          style="margin-top: -15px;"
                          v-model="new_question.options[index_op]"
                          :label="'Option '+(index_op+1)"
                          ref="name"
                          >
                          <template v-slot:append-outer>
                            <v-btn
                                color="red"
                                dark
                                text
                                v-if="index_op !== 0"
                                style="margin-top: -19px;"
                                :loading="loadingQuestion"
                                @click="new_question.options = new_question.options.filter(item => item !== new_question.options[index_op])"
                              >
                              <i class="fas fa-trash"></i>
                              </v-btn>
                          </template>
                        </v-text-field>
                          </div>
                          <v-btn
                            color="green"
                            style="margin-top: -15px;"
                            dark
                            class="float-right"
                            :loading="loadingQuestion"
                            @click="new_question.options.push((new_question.options.length+1))"
                          >
                          Add Option
                          </v-btn>
                 </v-col>
                </v-row>
                            
                                 
              </div>
          
            </v-card-text>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  dark
                  :loading="loadingQuestion"
                  @click="saveQR('add')"
                >
                Save
                </v-btn>
                
                <v-btn
                  color="primary"
                  text
                  @click="dialogImageNew = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>







          <v-dialog
          persistent
        v-model="dialogPassword"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Reset Password
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
             <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
             <v-radio-group   v-model="selectedPasswordRadio">
                   <v-radio
                      :label="`Automatically generate a password`"
                      :value="1"
                     
                    ></v-radio>
                      <v-text-field
                          id="name"
                          name="name"
                          outlined
                          class="mt-3"
                          style="password"
                          v-if="selectedPasswordRadio == 1"
                          label="Password"
                          width="300px"
                          ref="name"
                          v-model="selectedPassword"
                          />
                     <v-radio
                      :label="`Create password`"
                      :value="0"
                    ></v-radio>
                      <v-text-field
                          id="name"
                          name="name"
                          class="mt-3"
                          style="password"
                          v-if="selectedPasswordRadio == 0"
                          outlined
                          label="Password"
                          width="300px"
                          ref="name"
                          v-model="selectedPassword"
                          />
             </v-radio-group>
           
  
          </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingUpload2"
              @click="resetPassword(selectedPasswordId)"
            >
              Reset
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingUpload2"
              @click="dialogPassword = false,selectedPasswordRadio = 1"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
          <v-dialog
            v-model="creditDialog"
            width="700"
          >
           <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Move QR Code
          </v-card-title>
  
          <v-card-text style="margin-top:30px;">
            <v-select
            outlined
            dense
            :prepend-icon="'fa-folder'"
            :items="folders"
                  v-model="selected_qr.folder_id"
                  item-text="name"
                  item-value="id"
            ></v-select>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="saveQR()"
            >
              Move
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="creditDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
          </v-dialog>



        

           <v-dialog
            v-model="process_status_dialog"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Transaction {{transaction.Key}}
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <span class="text-h5" >
                  <b>Status: </b>
                </span>
                <span class="text-h5" v-if="!transaction.ProcessingException" >
                  {{transaction.Status}}
                </span>
                <span class="text-h5" v-else >
                  {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
                </span>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="process_status_dialog = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogEdit"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                QR Edit
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <v-select
                  :items="['Website','VCard']"
                  solo
                  dense
                  v-model="selected_qr.type"
                ></v-select>
                <v-subheader style="padding: 0px;">Enter {{selected_qr.type}} URL</v-subheader>
              <v-text-field
                color="#e91e63"
                outlined
                v-model="selected_qr.link"
                solo
                dense
              >
              </v-text-field>
            <v-subheader style="padding: 0px;">Enter QR Code Name</v-subheader>
              <v-text-field
                color="#e91e63"
                outlined
                v-model="selected_qr.name"
                solo
                dense
              >
              </v-text-field>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  text
                  @click="saveQR()"
                >
                  Save
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="dialogEdit = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
           <v-dialog
            v-model="dialogURL"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Company URLs{{ selected_question }}
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <h3><span style="color:teal;">Login: </span>{{baseUrl+'/login/'+company_url}}<v-btn
                              class="mx-2"
                              text
                              dark
                              @click="copyURL(baseUrl+'/login/'+company_url)"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                copy
                              </v-icon></v-btn></h3>
              </v-card-text>
              <v-card-text >
                <h3><span style="color:teal;">Signup: </span>{{baseUrl+'/signup/'+company_url}}<v-btn
                              class="mx-2"
                              text
                              dark
                              @click="copyURL(baseUrl+'/signup/'+company_url)"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                copy
                              </v-icon></v-btn></h3>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogURL = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
  </v-main>
  
  </v-app>
  
  </template>
  
  <script>
  import { VueTelInput } from 'vue-tel-input'
  import {Circle8} from 'vue-loading-spinner'
  import moment from 'moment';
  import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Login from '../QRGenModal.vue'
  import { Cropper } from 'vue-advanced-cropper';
  import Stencil from "./Stencil";
   import draggable from 'vuedraggable'
  import VueJsonToCsv from 'vue-json-to-csv'
  export default {
      components : {
        'RotateLoader': ClipLoader,DatePicker,Login,Cropper,
        VueJsonToCsv,Stencil,draggable
      },
      data () {
        return {
          header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
          },
          rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 3 || 'Min 3 characters',
            emailMatch: () => (`The email and password you entered don't match`),
          },
          transaction : [],
          loading:true,
          dialog:false,
          initials:'',
          valid:true,
          new_question:{ type : "h5", md : 12, class : "font-weight-bolder text-h5 text-typo mb-0",options : [1],label : "",name : ""},
          dialogImageNew : false,
          selected_question: '',
          dialogURL : false,
          company_url : '',
          loadingUpload2:false,
          selectedItems: [],
          type_options: [{name : "Header",id : "h5"},{name : "Text",id : "p"},{name : 'Radio Options',id : "radio"},{name : 'Text Input',id : "text"}],
          field_length: [{name : "Half",id : 6},{name : "Full",id : 12}],
          loadingSave: false,
          new_credit: '',
          selectedCompany: '',
          selectedFile : '',
          loadingAssign: false,
          creditDialog:false,
          share_selected_user : "",
          items : [],
          search_user : '',
          dialogImage: false,
          creditShareDialog : false,
          pageCount:1,
          dialogPassword: false,
          app_url : window.location.origin,
          api_url: process.env.VUE_APP_WASBI_URL,
          dialogEdit: false,
          pageSort: {},
          documentList:[],
          title: '',
          selected_qr : '',
          folders : [{id: "", name: "No Folder",}],
          totalCount:0,
          creditFlag: '',
          selectedPasswordId:'',
          selectedPasswordRadio: 1,
          selectedPassword:'',
          isLoading :false,
          page:1,
          itemsPerPage:10,
          selected_job:'',
          fields: '',
          search : "",
          dialogDoc:false,
          folder_id : "",
          datatable_options:{},
          offset: 0,
          selected_id : '',
          limit: 10,
          selected_project:'',
          image: '',
          dialogEdit : false,
          checkbox: '',
          qr_logo : '',
          qr_image_size : 3,
          qr_image : '',
          baseUrl : window.location.origin,
          process_status_dialog : false,
          headers_jobs: [
            { text: 'Page', value: 'title',
              sortable: true },       
              { text: 'Created At', value: 'created_at',
              sortable: false,width: '200', },
              { text: 'Updated At', value: 'updated_at',
              sortable: false,width: '200', },
  
            { text: 'Action', value: 'edit',
              sortable: false,width: '50' },
          ],
          projects: [
            
          ],
          users: []
        }
      },
      mounted  () {
        console.log('aa',this.$router);
        if(this.$route.params.id)
          this.folder_id = this.$route.params.id
      },
      created () { 
        console.log('aaa',this.$route);
        
          if(!localStorage.getItem('token'))
              this.$router.push('/login')
        this.getLinks();
      },
      methods:{
        async copyURL(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
          alert('Copied');
        } catch($e) {
          alert('Cannot copy');
        }
      },
      getDate(date){
        return moment(date).format('ll');
      },
      getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-question/"+this.$route.params.id, {headers: headers})
            .then(response => {
              let data = response.data.data;
              console.log(data);
              this.fields = JSON.parse(data.fields);
              this.title = data.title;
        })
      },
      roundEdges(canvas) {
      var context = canvas.getContext("2d");
      var width = canvas.width;
      var height = canvas.height;
      console.log(width);
      context.globalCompositeOperation = "destination-in";
      context.beginPath();
      context.scale(1, height/width);
      context.arc(width / 2, width / 2, width / 2, 0, Math.PI * 2);
      context.closePath();
      context.fill();
      return canvas;
    },
      uploadImage(event) {//console.log(event.target.files);
    // var input = event.target;
        // Ensure that you have a file before attempting to read it
        if (event) {
          // create a new FileReader to read this image and convert to base64 format
          var reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
            // Read image as base64 and set to imageData
            this.image = e.target.result;
          };
          console.log(this.image);
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsDataURL(event);
        }
      },
      cropImage() {
    // const result = this.$refs.cropper.getResult();
    // const newTab = window.open();
    const { canvas } = this.$refs.cropper.getResult();
    // const newTab = window.open();
        // newTab.document.body.innerHTML = `<img src="${this.roundEdges(canvas).toDataURL()}"></img>`;
    // const { canvas } = this.$refs.cropper[index].getResult();
    //     console.log(canvas);
    this.roundEdges(canvas).toBlob(blob => {
          console.log(blob);
          this.qr_logo = blob
        }, 'image/png')
        // const newTab = window.open();
        // newTab.document.body.innerHTML = `<img src="${result.canvas.toDataURL(
        //   "image/jpeg"
        // )}"></img>`;
      },
      changeQRLogo(){
        let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        if (!this.qr_logo) {
        Vue.$toast.error('QR logo is required', {
            position: 'top-right'
        });
        flag = 0;
      }else {
          this.loading = true
          const data = new FormData();
        data.append('qr_logo', this.qr_logo);
        data.append('qr_logo_size', this.qr_image_size);
        data.append('qr_size', this.selected_qr.qr_size);
        data.append('qr_color', this.selected_qr.color);

        if(this.selected_qr.id)
          data.append('id', this.selected_qr.id);
          axios({
            method: "POST",
            headers: headers,
            url: "/api/chnage-qr-logo",
            data: data,
            responseType: 'blob'
          })
            .then(response => {
              this.qr_image = response.data;
              let reader = new FileReader();
                reader.readAsDataURL(response.data); 
                reader.onload = () => {
                    this.qr_image = reader.result;
                }
              this.loading = false
              
              
            })
            .catch(err => {
              if(err.response.data.errors.qr_logo)
                  Vue.$toast.error('Invalid file format/Invalid file size(5Mb Max)', {
                  position: 'top-right'
                  });
            });
          }
      },
      downloadFile(url) {
  
        const imageName = 'your-image.png'; // Replace with the actual image name
        const downloadUrl = url;
  
        // Create a hidden link to trigger the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.style.display = 'none';
        document.body.appendChild(link);
        window.open(downloadUrl, '_blank');
        // Trigger the download
        // link.click();
  
        // Remove the link from the document
        document.body.removeChild(link);
      },
      viewContract(id){
        let url = process.env.VUE_APP_WASBI_URL
        window.open(url+id+'_user_agreement'+'.pdf', '_blank');
      },
        dateFormat(date){
          return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
        },
          formatDate(value) {
              return moment(value).format("DD MMM YYYY")
          },
          addCredit(){
            let id = this.selectedCompany;
             var r = this.creditFlag == 'remove' ? confirm("Are you sure, You went to remove credit?") : confirm("Are you sure, You went to add credit?");
            if (r == true) {
              this.loadingAssign = true;
              axios
                .post("/api/add-credit-admin/"+id,{
                  credit : this.new_credit,
                  flag : this.creditFlag
                },this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                  if(this.creditFlag == 'remove'){
                    Vue.$toast.success('Credit removed successfully', {
                        position: 'top-right'
                        });
                  }else{
                    Vue.$toast.success('Credit added successfully', {
                        position: 'top-right'
                        });
                  }
                        this.creditDialog = false;
                        this.new_credit = 0;
                        this.loadingAssign = false;
                      this.getUsers();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                        this.loadingAssign = false;
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          StartProcess1(flag){
            let id = this.selected_id;
            var r = confirm("Are you sure, You went to start process 1?");
              if (r == true) {
                this.loading = true;
                this.dialogDoc = false;
                      axios
                  .get("/api/process1/"+id+"/"+flag,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          Vue.$toast.success('Process started successfully', {
                              position: 'top-right'
                              });
                              this.loading = false;
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                              this.loading = false;
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          openDocModal(id){
            
            this.dialogDoc = true;
            axios
              .get("/api/get-documents/"+id,this.header)
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                  this.documentList = data.data;
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
             });
          },
          editItem(id){
            this.$router.push('/add-parking/'+id)
          },
          onFileChanged(e) {
            let self = this;
            let file = e.target.files[0]
            const data = new FormData();
              data.append('file',file);
              data.append('id',this.selectedFile);
              this.loading = true
                axios({
                method: "POST",
                url: "/api/upload-custom-qr",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                Vue.$toast.success("QR uploaded", {
                    position: 'top-right'
                    });
                self.getUsers();
                this.loading = false
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    this.isDisableUpload = false;
                    this.isDarkUpload = true;
                });
  
          },
          generatePassword() {
              var length = 8,
                  charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                  retVal = "";
              for (var i = 0, n = charset.length; i < length; ++i) {
                  retVal += charset.charAt(Math.floor(Math.random() * n));
              }
              return retVal;
          },
          onButtonClick(id) {
            this.$refs['file'].click()
            this.selectedFile = id;
          },
          deleteItem(id){
            let multiple_flag = 0
            if(id == 'all')
              multiple_flag = 1
            var r = confirm("Are you sure, You went to delete?");
            if(id == 'all' && this.selectedItems.length == 0){
              Vue.$toast.error("Select atleast one item to delete", {
                position: 'top-right'
                });
                r = false
            }
            if (r == true) {
              axios
                .post("/api/delete-qr/"+id,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getUsers();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          changeStatus(id,value){
              this.loading = true
               var r = confirm("Are you sure, You went to change status?");
              if (r == true) {
                      axios
                  .get("/api/change-status/"+id+"/"+value,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          Vue.$toast.success('Profile status changed successfully', {
                              position: 'top-right'
                              });
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          resetQR(id){
              this.loading = true
               var r = confirm("Are you sure, You went to reset QR to Origianl?");
              if (r == true) {
                      axios
                  .get("/api/reset-qr-image/"+id,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                         
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          saveQR(flag = ''){
              if(flag == 'add'){
                this.fields.push(this.new_question);
              }
              this.loadingSave = true
                      axios
                  .post("/api/save-question",{id : this.$route.params.id,fields : this.fields},this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                              this.dialogImage = false;
                              this.loadingSave = false
                              this.getLinks();
                              Vue.$toast.success('Field updated', {
                            position: 'top-right'
                            });
                            if(flag == 'add'){
                              this.dialogImageNew = false;
                              this.new_question = { type : "h5", md : 12, class : "",options : [1],label : "",name : ""};
                            }
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              
          },
          shareQR(){
              this.loading = true
              if(this.share_selected_user){
                      axios
                  .post("/api/qr-share",{id : this.selected_qr.id, user : this.share_selected_user.user_id},this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                        Vue.$toast.success('QR Code shared', {
                              position: 'top-right'
                              });
                              this.loading = false
                              this.creditShareDialog = false;
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                    }else{
                      Vue.$toast.error('Please select one user', {
                      position: 'top-right'
                      });
                      this.loading = false
                    }
                  
              
          },
          syncGhlChat(id,value){
            if(value == 0){
            var r = confirm("Are you sure, You went to sync with GHL?");
              if (r == true) {
                localStorage.setItem('ghl_callback_company_id',id)
                let url = "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri="+process.env.VUE_APP_GHL_REDIRECT_URL+"&client_id="+process.env.VUE_APP_GHL_CLIENT_ID+"&scope=contacts.readonly%20conversations.readonly%20conversations.write%20conversations/message.readonly"
                window.location.href = url;
  
  
              }
            }
          },
          syncGhl(id,value){
            var r = confirm("Are you sure, You went to sync with GHL?");
              if (r == true) {
                this.loading = true;
                      axios
                  .post("/api/sync-ghl/"+id+"/"+value,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                        if(value == 1)
                          Vue.$toast.success('GHL sync turned off successfully', {
                              position: 'top-right'
                              });
                        else
                            Vue.$toast.success('GHL synced successfully', {
                            position: 'top-right'
                            });
                        this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          changeType(){
            if(this.selected_question.type == 'h5'){
              this.selected_question.class="font-weight-bolder text-h5 text-typo mb-0"
            }
            else{
              this.selected_question.class=""
            }
            
          },
          changeTypeAdd(){
            if(this.new_question.type == 'h5'){
              this.new_question.class="font-weight-bolder text-h5 text-typo mb-0"
            }
            else{
              this.new_question.class=""
            }
            
          },
          getUsers(){
              this.loading = true;
              this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
              this.selected_project = name;
              let req = {
                limit : this.datatable_options.itemsPerPage,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search,
                folder_id : this.folder_id,
              }
              axios({
            method: "POST",
             
            url: "/api/get-questions",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.users = data.data;
                  this.pageCount = data.total_page
                  this.totalCount = data.count
                  console.log( this.users);
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
          resetPassword(id){
            this.loading = true
               var r = confirm("Are you sure, You went to reset password?");
              if (r == true) {
                this.loadingUpload2 = true;
                      axios
                  .post("/api/reset-password-company/"+id,{password: this.selectedPassword},this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          this.loading = false
                          Vue.$toast.success('Password reset successfully', {
                              position: 'top-right'
                              });
                              this.selectedPasswordRadio = 1;
                              this.dialogPassword = false;
                               this.loadingUpload2 = false;
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  this.loading = false
                  return false;
              }
          },
          clientLogin(id){
          if(confirm('Are you sure, You want to login as a client?')){
            axios({
              method: "POST",
              
              url: "/api/auth/login-admin-client-user-id",
              data: {
                user_id: id              }
            })
              .then(response => {
                let data = response.data;
                if (data.flag == 1) {
                  let result = data.data;
                  localStorage.setItem("from_admin",1);
                  localStorage.setItem("from_admin_email",localStorage.getItem('email'));
                  localStorage.setItem("token", data.token.token);
                  localStorage.setItem("user_id", result.user_id);
                  localStorage.setItem("email", result.email);
                  localStorage.setItem("phone", result.phone_m);
                  localStorage.setItem("name", result.first_name+' '+result.last_name);
                  localStorage.setItem("is_admin",1);
                  localStorage.setItem("role", JSON.stringify(result.roles[0]));
                  this.$router.push("/admin/qr-codes");
                  location.reload();
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
          }
        },
          searchUser(val){
            axios({
                method: "POST",
                 
                url: "/api/get-search-users",
                data : {search : val},
                header : { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
                  }
              })
              .then(response => {
                  
                  this.items = response.data.data;
              })
          },
          getProcessStatus(id){
              this.loading = true;
              this.offset = (this.page - 1) * 10
              axios({
                method: "GET",
                 
                url: "/api/get-process-status/"+id,
                header : { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
                  }
              })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.transaction = data.data.value[0];
                  console.log(this.transaction);
                  this.process_status_dialog = true;
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
          
          
  
      },
      watch: {
              datatable_options: {
                  handler () {
                      console.log(this.datatable_options);
                  this.getUsers()
                  },
                  deep: true,
              },
              search_user (val) {
                    if(val)
                      this.searchUser(val);
              },
          },
      
  }
  </script>
  
  <style >
  .qr-manage-code__title {
    color: #797d80;
    border-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    padding: 2px 8px;
    margin: 0 0 10px;
  
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    line-height: 1.2;
    vertical-align: middle;
    position: relative;
    left: -9px;
  }
  .qr-manage-code__type {
    font-size: 12px;
    color: #d4d4d4;
    line-height: 1;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    cursor: default;
  }
  .qr-manage-code__folder {
    font-size: 14px;
    color: #a4a6a8;
    white-space: nowrap;
    cursor: default;
  }
  .qr-manage-code__date {
    font-size: 14px;
    color: #a4a6a8;
    white-space: nowrap;
    position: relative;
    cursor: default;
  }
  .qr-manage-code__url {
    border-left: 1px solid #f7f7f7;
    padding: 0 0 0 55px;
    margin: 0;
    color: #a4a6a8;
    font-size: 14px;
    position: relative;
    float: left;
    top: -50px;
    left: 135px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 255px;
  }
   .qr-manage-code__url-link {
    color: #797d80;
    display: inline-block;
  }
  .qr-manage-code__url a {
    max-width: 100%;
    white-space: nowrap;
    display: inline-block;
    color: inherit;
    text-decoration: none;
    color: #797d80;
    display: inline-block;
  }
  .qr-manage-code__target a {
    width: fit-content;
    padding: 1px 6px;
    margin: -1px 0 0 -6px;
    z-index: 1;
  }
  .qr-manage-code__stats {
  
    right: 385px;
    top: 30px;
    border-left: 1px solid #f7f7f7;
    padding: 6px 0 5px 24px;
    margin: 0;
    width: 130px;
  }
  .qr-manage-code__scans {
    color: #797d80;
    font-size: 25px;
    font-weight: 600;
    line-height: 1;
    cursor: default;
  }
  .qr-manage-code__scans-label {
    font-size: 14px;
    color: #a4a6a8;
    line-height: 1;
    cursor: default;
  }
  .qr-manage-code__insights {
    display: inline-block;
    border: 0;
    background: transparent;
    color: #47bdef;
    padding: 0;
    margin: 7px 0 0;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }
  @media screen and (max-width: 768px) {
        .mobile table.v-table tr {
          max-width: 100%;
          position: relative;
          display: block;
        }
  
        .mobile table.v-table tr:nth-child(odd) {
          border-left: 6px solid deeppink;
        }
  
        .mobile table.v-table tr:nth-child(even) {
          border-left: 6px solid cyan;
        }
  
        .mobile table.v-table tr td {
          display: flex;
          width: 100%;
          border-bottom: 1px solid #f5f5f5;
          height: auto;
          padding: 10px;
        }
  
        .mobile table.v-table tr td ul li:before {
          content: attr(data-label);
          padding-right: .5em;
          text-align: left;
          display: block;
          color: #999;
  
        }
        .v-datatable__actions__select
        {
          width: 50%;
          margin: 0px;
          justify-content: flex-start;
        }
        .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
          background: transparent;
        }
  
      }
      .mobile .v-data-table__mobile-row__header {
      display: none;
  }
  
  .mobile  .v-data-table__mobile-row__cell {
      text-align: left !important;
  }
  .mobile .v-data-table__mobile-row{
    justify-content: center;
  }
  </style>
  