<template>
    <v-app class="bg-white">
    <vue-header-no-login></vue-header-no-login>
    <v-main class="auth-pages mt-10">
        <v-row  style="margin-top: 100px;margin-right: 0px;">
        <v-col cols="12" md="12" lg="12">
          <v-container class="pb-0 mt-10 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
            <div class="terms-and-conditions">
    <h1>Welcome to PASS Profile!</h1>
    <p>These Terms and Conditions govern your use of our services, including our website, applications, and other offerings (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms and Conditions.</p>

    <h2>1. Acceptance of Terms</h2>
    <p>By accessing or using our Services, you agree to comply with and be bound by these Terms and Conditions and our Privacy Policy. If you do not agree with any part of these terms, you must not use our Services.</p>

    <h2>2. Changes to Terms</h2>
    <p>We may modify these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on our website. You are advised to review these Terms periodically for any changes. Changes to these Terms and Conditions are effective when they are posted on this page.</p>

    <h2>3. Use of Services</h2>
    <ul>
      <li><strong>Eligibility:</strong> You must be at least 18 years old to use our Services.</li>
      <li><strong>Account Registration:</strong> To access certain features of our Services, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</li>
      <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account. You agree to notify us immediately of any unauthorized use, or suspected unauthorized use of your account or any other breach of security.</li>
    </ul>

    <h2>4. Intellectual Property</h2>
    <p>Ownership: All content, features, and functionality (including but not limited to text, graphics, logos, images, and software) provided through our Services are owned by PASS Profile or our licensors and are protected by intellectual property laws.</p>
    <p>License: We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal, non-commercial use, subject to these Terms and Conditions.</p>

    <h2>5. Prohibited Conduct</h2>
    <p>You agree not to use the Services to:</p>
    <ul>
      <li>Violate any applicable law or regulation.</li>
      <li>Infringe upon or violate our intellectual property rights or the intellectual property rights of others.</li>
      <li>Engage in any conduct that is unlawful, fraudulent, or harmful to others.</li>
      <li>Transmit any viruses, malware, or other harmful code.</li>
      <li>Interfere with the operation of the Services or any user’s enjoyment of the Services.</li>
    </ul>

    <h2>6. Termination</h2>
    <p>We may terminate or suspend your access to our Services, without prior notice or liability, for any reason, including if you breach these Terms and Conditions. Upon termination, your right to use the Services will immediately cease.</p>

    <h2>7. Limitation of Liability</h2>
    <p>To the maximum extent permitted by law, PASS Profile and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use or inability to use the Services...</p>

    <h2>8. Governing Law</h2>
    <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</p>

    <h2>9. Dispute Resolution</h2>
    <p>Any disputes arising out of or relating to these Terms and Conditions or your use of the Services will be resolved through binding arbitration in accordance with the rules of [Arbitration Institution], and not in court. You agree to waive any right to a jury trial or to participate in a class action.</p>

    <h2>10. Miscellaneous</h2>
    <p>Severability: If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>
    <p>Entire Agreement: These Terms and Conditions constitute the entire agreement between you and PASS Profile regarding the use of our Services and supersede any prior agreements between you and PASS Profile.</p>
    <p>Contact Us: If you have any questions about these Terms and Conditions, please contact us at info@pass-profile.com.</p>
  </div>
            </v-container>
            </v-col>
            </v-row>
</v-main>
</v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>