<template>
<v-app id="inspire">

<vue-header></vue-header>
<v-main style="padding:0px!important;">
    <v-container style="margin-top:200px;margin-bottom: 72px;">
      <v-card class="card-shadow border-radius-xl mt-6" id="basic">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Basic Info</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-row>
        <v-col cols="6">
          <v-text-field
            color="#e91e63"
            label="First Name"
            v-model="user.first_name"
            class="font-size-input input-style"
          >
          </v-text-field>
        </v-col>
        
        <v-col cols="6">
          <v-text-field
            color="#e91e63"
            label="Last Name"
            v-model="user.last_name"
            class="font-size-input input-style"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="py-0">
          <v-text-field
            color="#e91e63"
            label="Email"
            disabled
            v-model="user.email"
            class="font-size-input input-style"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            color="#e91e63"
            v-model="user.phone_m"
             v-mask="'(###) ###-####'"
            label="Phone(Mobile)"
            class="font-size-input input-style"
          >
          </v-text-field>
        </v-col>
    
          <v-col cols="12"  sm="6" md="6" lg="6"  >
                     <p for="my-file">Profile Picture</p>
                      <v-btn
                              type="button"
                              color="blue"
                              small
                              :loading="loadingUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="changeTrigger = true"
                            >
                              Select profile picture
                            </v-btn>
                        <avatar-cropper
                          :upload-handler="handleUploaded"
                          :output-mime="cropperOutputMime"
                          v-model="changeTrigger"
                          />

                      <div class="border p-2 mt-3">
                      <p>Preview Here:</p>
                      <template >
                        <v-img contain v-if="!preview" src="@/assets/img/avatar.png" class="img-fluid" />
                        <v-img contain v-else :src="preview" class="img-fluid" />
                      </template>
                    </div>
                      </v-col>
      </v-row>
      <v-row>
        <v-btn
          color="#4CAF50"
          large
           @click="saveProfile"
                        :loading="loading"
          class="
            font-weight-bolder
            btn-default
            mb-5
            px-8
            ms-auto
          "
          
        >
          Save
        </v-btn>
      </v-row>
    </div>
  </v-card>
      
    </v-container>
    
     <vue-footer></vue-footer>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import AvatarCropper from 'vue-avatar-cropper'
export default {
components: {
      VueTelInput,AvatarCropper
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        cropperOutputMime : '',
        changeTrigger : false,
        preview: '',
        role : '',
        loadingUpload: false,
        user: [],
        companyname : '',
        sector : '',
        isDark: true,
        loading:false,
        isDisable :false,
        no_of_employee : '',
        showPassword : false,
        file_flag : {
          agreement : {
                          name : '',
                          id :''
                        },
          id :{
                          name : '',
                          id :''
                        },
          bill :{
                          name : '',
                          id :''
                        },
          letter_a : {
                          name : '',
                          id :''
                        },
          letter_b : {
                          name : '',
                          id :''
                        },
           letter_c : {
                          name : '',
                          id :''
                        },
        },
        phoneFlag:0,
        emailFlag:0,
        files : {
          agreement : [],
          id : [],
          bill:[],
          letter_a : [],
          letter_b : [],
          letter_c : []
        },
        country :'',
        otp_email:'',
        otp_phone:'',
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: ""

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    disableinput($event){
       $event.preventDefault();
    },
    downloadFile(key){
      let self = this;
            this.isDisableDownload = true;
            this.isDarkDownload = false;
                axios({
                    method: "POST",
                    url: "/api/download-file",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {key : key}
                    })
                    .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');

                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', response.headers['file-name']);
                   document.body.appendChild(fileLink);

                   fileLink.click();
                    
                    self.isDisableDownload = false;
                    self.isDarkDownload = true;
                    })
                    .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.user.city = addressData.locality;
            this.user.state = addressData.administrative_area_level_1;
            this.user.zip = addressData.postal_code
        },
    uploadFile(id){
      let self = this;
      let flag = 1;
      if(id =='agreement' && self.files.agreement.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else if(id =='id' && self.files.id.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='bill' && self.files.bill.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_a' && self.files.letter_a.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_b' && self.files.letter_b.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_c' && self.files.letter_c.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else{
          const data = new FormData();
          if(id == 'agreement')
            data.append('file_agreement', self.files.agreement);
          if(id == 'id')
            data.append('file_id', self.files.id);
          if(id == 'bill')
            data.append('file_bill', self.files.bill);
          if(id == 'letter_a')
            data.append('file_letter_a', self.files.letter_a);
          if(id == 'letter_b')
            data.append('file_letter_b',self.files.letter_b);
          if(id == 'letter_c')
            data.append('file_letter_c',self.files.letter_c);
          data.append('flag',id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      }
    },
    deleteChip(index){
      if(index == 'agreement')
        this.file_flag.agreement.name = ''
      if(index == 'id')
        this.file_flag.id.name = '';
      if(index == 'bill')
        this.file_flag.bill.name = '';
      if(index == 'letter_a')
        this.file_flag.letter_a.name = '';
      if(index == 'letter_b')
        this.file_flag.letter_b.name = '';
      if(index == 'letter_c')
        this.file_flag.letter_c.name = '';
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success('Password has been changed', {
                position: 'top-right'
                });
            this.password = '';
            this.confpassword = '';

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },
   handleUploaded(cropper) {
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          let preview_url =process.env.VUE_APP_WASBI_URL;
          const data = new FormData();
        data.append('file', img);
        data.append('client_id', this.user.user_id);
        this.loadingUpload = true;
        axios({
          method: "POST",
          url: "/api/upload-avatar",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Profile Picture uploaded", {
              position: 'top-right'
              });
              this.getUserProfile()
              this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });

        },
        dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    },
    getUserProfile() {
    let flag = 1;
    let self = this;
    let avatar_url = process.env.VUE_APP_WASBI_URL
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              this.preview = data.user.avatar ? data.user.avatar : ''
              if(this.user.smart_credit_password)
                this.user.smart_credit_password = atob(this.user.smart_credit_password);
              if(this.user.my_free_score_password)
                this.user.my_free_score_password = atob(this.user.my_free_score_password);
              if(data.docs.length != 0){
                data.docs.forEach(element => {
                  console.log(element);
                  if(element.file_name.includes("ID")){
                    self.file_flag.id.name = element.file_old_name;
                    self.file_flag.id.id = element.file_name;
                  }
                  if(element.file_name.includes("Agrement")){
                    self.file_flag.agreement.name = element.file_old_name;
                    self.file_flag.agreement.id = element.file_name;
                  }
                  if(element.file_name.includes("Bill")){
                    self.file_flag.bill.name = element.file_old_name;
                    self.file_flag.bill.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_A")){
                    self.file_flag.letter_a.name = element.file_old_name;
                    self.file_flag.letter_a.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_B")){
                    self.file_flag.letter_b.name = element.file_old_name;
                    self.file_flag.letter_b.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_C")){
                    self.file_flag.letter_c.name = element.file_old_name;
                    self.file_flag.letter_c.id = element.file_name;
                  }
                });
              }
              console.log(self.files);
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.phone_m && flag == 1) {
            Vue.$toast.error('phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
          this.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
            this.loading = false;
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
    },

}
</script>

