<template>
  <v-app id="inspire" style="background:#F9F9F9;">
  
  <vue-header-admin></vue-header-admin>
    <v-main >
      <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
        
         <v-tabs
         class="mt-3"
         color="#4CAF50"
                    left
                  >
                    <v-tab>General</v-tab>
                    <v-tab>Templates</v-tab>
                    <!-- <v-tab>Custom Links</v-tab> -->
                    <v-tab>AI Prompt 1 </v-tab>
                    <v-tab>AI Prompt 2 </v-tab>
                    <v-tab>AI Prompt 3 </v-tab>
                    <v-tab>AI Prompt 4 </v-tab>
                    <v-tab>AI Prompt 5 </v-tab>
                    <!-- <v-tab>Grant Settings</v-tab> -->
                    <v-tab-item
                    style="background-color: transparent!important;"
                      :key="0"
                    >
          <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">Company Logo</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <label for="my-file">Choose Logo </label>
                   <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
                   <div class="border p-2 mt-3">
                      <p>Preview Here:</p>
                      <template v-if="preview">
                        <v-img contain :src="preview" class="img-fluid" />
                      </template>
                    </div>
                    
                   <v-btn
                      dark
                    color="red"
                    class="float-right ml-2 mt-2"
                    @click="removeLogo"
                  >Remove Logo</v-btn>
                     <v-btn
                      dark
                      :loading="loader_logo"
                    color="green"
                    class="float-right mt-2"
                    @click="uploadLogo"
                  >Upload</v-btn>
                    
                    </v-col>
                    </v-row>
                    </v-card-text>
                    <!-- <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions> -->
                    </v-card>
                    </v-col>
                    </v-row>
                    </v-tab-item>
                    <v-tab-item
                    style="background-color: transparent!important;"
                      :key="1"
                    >
          <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">Settings</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                      <v-btn
                      outlined
                      style="float: right;"
                        height="55px"
                        color="success"
                        class="justify-center"
                        @click="showPlaceholder = !showPlaceholder"
                      >View Template Placeholders</v-btn>
                    </v-col>
                      <div class="col-12" v-if="showPlaceholder">
                        <div class="content-box m-b-0" style="border: solid 1px #dddbda;
padding: 20px !important;">
                            <div class="row">
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                        {company_logo} - <span class="font-400">Company logo</span><br>
                                        {Name} - <span class="font-400">Full name of client</span><br>
                                        {Company} - <span class="font-400">Company of client</span><br>
                                        {Email} - <span class="font-400">Email of client</span><br>
                                        {Phone} - <span class="font-400">Phone number of client</span><br>
                                        {Date} - <span class="font-400">Current date</span><br>
                                        {page_break} - <span class="font-400">Page break</span>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                      {1_Prompt} - <span class="font-400">AI Prompt 1</span><br>
                                      {2_Prompt} - <span class="font-400">AI Prompt 2</span><br>

                                      {3_Prompt} - <span class="font-400">AI Prompt 3</span><br>

                                      {4_Prompt} - <span class="font-400">AI Prompt 4</span><br>

                                      {5_Prompt} - <span class="font-400">AI Prompt 5</span><br>

                                    </p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                      <v-col
                        cols="12"
                        md="12"
                      >
                      <p style="text-align:left;" class="text-h6">Pass Profile Template</p>
                      <html-editor v-model="settings.pass_profile_template"></html-editor>
                    
                    </v-col>
                    <v-col
                        cols="12"
                        md="12"
                      >
                      <p style="text-align:left;" class="text-h6">Email Template</p>
                      <html-editor v-model="settings.email_template"></html-editor>
                    
                    </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
              
            </v-row>
        </v-tab-item>
  
       
           <v-tab-item
                    style="background-color: transparent!important;"
                      :key="2"
                    >
                    <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">AI Prompt 1</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-textarea label="Initial" outlined v-model="settings.prompt.prompt_1.initial">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <v-textarea label="Step 1" outlined v-model="settings.prompt.prompt_1.prompt_a">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <v-textarea label="Step 2" outlined v-model="settings.prompt.prompt_1.prompt_b">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                    </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>
                    </v-card>
                    </v-col>
                    </v-row>
            </v-tab-item>
            <v-tab-item
                    style="background-color: transparent!important;"
                      :key="3"
                    >
                    <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">AI Prompt 2</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-textarea label="Initial" outlined v-model="settings.prompt.prompt_2.initial">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-textarea height="500px" label="Step 1" outlined v-model="settings.prompt.prompt_2.prompt_a">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                    
                    </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>

                    </v-card>
                    </v-col>
                    </v-row>
            </v-tab-item>
            <v-tab-item
                    style="background-color: transparent!important;"
                      :key="4"
                    >
                    <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">AI Prompt 3</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-textarea label="Initial" outlined v-model="settings.prompt.prompt_3">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                  
                    
                    </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>
                    </v-card>
                    </v-col>
                    </v-row>
            </v-tab-item>
            <v-tab-item
                    style="background-color: transparent!important;"
                      :key="5"
                    >
                    <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">AI Prompt 4</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-textarea height="500px" label="Initial" outlined v-model="settings.prompt.prompt_4">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                  
                    </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>

                    </v-card>
                    </v-col>
                    </v-row>
            </v-tab-item>
            <v-tab-item
                    style="background-color: transparent!important;"
                      :key="6"
                    >
                    <v-row
              align="center"
              justify="center"
              class=" mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                         
                      <v-col
                        cols="12"
                        md="9"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">AI Prompt 5</h5>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-textarea label="Initial" outlined v-model="settings.prompt.prompt_5">

                    </v-textarea>
                    
                   
                    
                    </v-col>
                  
                    </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                    <v-row>
                      <v-col>
                        <v-btn
                          color="#4CAF50"
                          large
                          :loading="loader_sub"
                          @click="saveSettings"
                          class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mt-5
                          "
                          
                        >
                          Save
                        </v-btn>
                        </v-col>
                      </v-row>
                  </v-card-actions>

                    </v-card>
                    </v-col>
                    </v-row>
            </v-tab-item>
            <v-tab-item
                    style="background-color: transparent!important;"
                      :key="3"
                    >
                    <v-row
              align="center"
              justify="center"
              class="mt-10 mb-5"
            >
              <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                  <v-card-text style="margin-bottom: 0px;" >
                    <v-row>
                        
                      <v-col
                        cols="12"
                        md="12"
                      >
                      <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                      <h5 class="text-h4 font-weight-bold text-typo">Focus Areas({{totalCountFA}})</h5>
                      </v-col>
                      <v-col cols="12"
                        md="12" >
                           <v-btn
                                      color="#4CAF50"
                          class="
                           ont-weight-bolder
                            btn-default
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                          small
                                          style="float: left;"
                                          @click="dialogAddFA = true"
                                      >
                                          Add Focus Area
                                      </v-btn>
                        </v-col>
                      <v-col cols="12"
                        md="12">
                        
                          <v-data-table
                              :headers="headers_fa"
                              :options.sync="datatable_options_fa"
                              :items="focus_area"
                              loading-text="Loading... Please wait"
                              :page.sync="page_fa"
                              :items-per-page="itemsPerPage_fa"
                              hide-default-footer
                              class="table"
                          >
                       
                          <template v-slot:item.is_iframe="{ item }">
                              {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                          </template>
                          <template v-slot:item.email="{ item }">
                              {{item.email ? item.email : 'Not Available'}}
                          </template>
                           <template v-slot:item.phone="{ item }">
                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                          </template>
                          <template v-slot:item.date_of_birth="{ item }">
                              {{dateFormat(item.dob)}}
                          </template>
                          <template v-slot:item.price="{ item }">
                              {{'$'+item.price}}
                          </template>
                          <template v-slot:item.title="{ item }" >
                             <span style="cursor:pointer;color:blue;" @click="$router.push('edit-letter/'+item.id)"> {{item.title}} </span>
                          </template>
                          <template v-slot:item.status="{ item }">
                              {{item.status == 1 ? 'Active' :'Inactive'}}
                          </template>
                          <template v-slot:item.edit="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="dialogAddFA = true"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.password="{ item }">
                               <v-btn
                              class="mx-2"
                              fab
                              dark
                              @click="dialogDetail = true,selectedItem = item"
                              x-small
                              color="green"
                            >
                              {{item.total_count}}
                            </v-btn>
                          </template>
                        
                          <template v-slot:item.address="{ item }">
                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                          </span>
                          </template>
                          <template v-slot:item.status_id="{ item }">
                             <v-btn
                              class="mx-2"
                              dark
                              @click="changeStatus(item.user_id,item.status)"
                              x-small
                              :color="item.status==1 ? 'red':'green'"
                            >
                            {{item.status==1 ? 'Deactivate':'Activate'}}
                            </v-btn>
                          </template>
                          <template v-slot:item.document="{ item }">
                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                          </template>
                            <template v-slot:item.edit="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="dialogAddFA = true, fa = item"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.id="{ item }">
  
                            <v-btn
                              class="mx-2"
                              text
                              dark
                              @click="deleteItemFA(item.id)"
                              x-small
                              color="red"
                            >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                                delete
                              </v-icon>
                            </v-btn>
                          </template>
                          </v-data-table>
                          <div class="text-center pt-2">
                              <v-pagination
                              circle
                              prev-icon="fa fa-angle-left"
                              next-icon="fa fa-angle-right"
                              class="pagination"
                              color="#4CAF50"
                                  v-model="page_fa"
                                  :length="pageCountFA"
                                  @input="getFA"
                              ></v-pagination>
                              </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
                 <v-col cols="12" md="12">
                <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                  <v-card-text style="margin-bottom: -30px;" >
                    <v-row>
                        
                      <v-col
                        cols="12"
                        md="12"
                      >
                      <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                      <h5 class="text-h4 font-weight-bold text-typo">Locations({{totalCountL}})</h5>
                      </v-col>
                      <v-col cols="12"
                        md="12" >
                           <v-btn
                                      color="#4CAF50"
                          class="
                           ont-weight-bolder
                            btn-default
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                          small
                                          style="float: left;"
                                          @click="dialogAddL = true"
                                      >
                                          Add Location
                                      </v-btn>
                        </v-col>
                      <v-col cols="12"
                        md="12">
                        
                          <v-data-table
                              :headers="headers_l"
                              :options.sync="datatable_options_l"
                              :items="locations"
                              loading-text="Loading... Please wait"
                              :page.sync="page_l"
                              :items-per-page="itemsPerPage_l"
                              hide-default-footer
                              class="table"
                          >
                       
                          <template v-slot:item.is_iframe="{ item }">
                              {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                          </template>
                          <template v-slot:item.email="{ item }">
                              {{item.email ? item.email : 'Not Available'}}
                          </template>
                           <template v-slot:item.phone="{ item }">
                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                          </template>
                          <template v-slot:item.date_of_birth="{ item }">
                              {{dateFormat(item.dob)}}
                          </template>
                          <template v-slot:item.price="{ item }">
                              {{'$'+item.price}}
                          </template>
                          <template v-slot:item.title="{ item }" >
                             <span style="cursor:pointer;color:blue;" @click="$router.push('edit-letter/'+item.id)"> {{item.title}} </span>
                          </template>
                          <template v-slot:item.status="{ item }">
                              {{item.status == 1 ? 'Active' :'Inactive'}}
                          </template>
                          <template v-slot:item.edit="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="openEdit(item)"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.password="{ item }">
                               <v-btn
                              class="mx-2"
                              fab
                              dark
                              @click="dialogDetail = true,selectedItem = item"
                              x-small
                              color="green"
                            >
                              {{item.total_count}}
                            </v-btn>
                          </template>
                        
                          <template v-slot:item.address="{ item }">
                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                          </span>
                          </template>
                          <template v-slot:item.status_id="{ item }">
                             <v-btn
                              class="mx-2"
                              dark
                              @click="changeStatus(item.user_id,item.status)"
                              x-small
                              :color="item.status==1 ? 'red':'green'"
                            >
                            {{item.status==1 ? 'Deactivate':'Activate'}}
                            </v-btn>
                          </template>
                          <template v-slot:item.document="{ item }">
                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                          </template>
                            <template v-slot:item.edit="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="dialogAddL = true,location = item"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.id="{ item }">
  
                            <v-btn
                              class="mx-2"
                              text
                              dark
                              @click="deleteItemLocation(item.id)"
                              x-small
                              color="red"
                            >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                                delete
                              </v-icon>
                            </v-btn>
                          </template>
                          </v-data-table>
                          <div class="text-center pt-2">
                              <v-pagination
                              circle
                              prev-icon="fa fa-angle-left"
                              next-icon="fa fa-angle-right"
                              class="pagination"
                              color="#4CAF50"
                                  v-model="page_l"
                                  :length="pageCountL"
                                  @input="getL"
                              ></v-pagination>
                              </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            </v-tab-item>
         </v-tabs>
        
             <v-dialog
          persistent
        v-model="dialogAssign"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
            Add new Product
          </v-card-title>
          <v-card-title class="text-h5 grey lighten-2" v-else>
            Edit Product
          </v-card-title>
          <v-card-text style="margin-top:30px;"> 
            <v-text-field
                  label="Credit"
                  v-model="product.credit"
                  v-mask="'###########################'"
            ></v-text-field>
            <v-text-field
                  v-model="product.price"
                  label="Price"
                  prefix="$"
                  v-mask="'###########################'"
            ></v-text-field>
                <v-select
                label="Type"
              v-model="product.type"
              :items="['Company','Client']"
            ></v-select>
            <v-text-field
                  v-model="product.name"
                  label="Name"
            ></v-text-field>
            <v-col cols="12">
                       <v-data-table
                            :headers="headers_basic"
                            :items="product.items"
                            sort-by="calories"
                            class="table mb-10"
                          >
                            <template v-slot:top>
                              <v-toolbar
                                flat
                              >
                                <v-toolbar-title>Items</v-toolbar-title>
                                <v-divider
                                  class="mx-4"
                                  inset
                                  vertical
                                ></v-divider>
                                <v-spacer></v-spacer>
                                <v-btn
                                      color="blue"
                                      dark
                                      @click="dialog_basic = true,selected_item = 'plan1'"
                                      class="mb-2"
                                    >
                                      New Item
                                    </v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                class="material-icons-round mr-2"
                                @click="editItemBasic('plan1',item)"
                              >
                                edit
                              </v-icon>
                              <v-icon
                                small
                                class="material-icons-round"
                                @click="deleteItemBasic('plan1',item)"
                              >
                                delete
                              </v-icon>
                            </template>
                          </v-data-table>
                     </v-col>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            v-if="!product.id"
              color="#4caf50"
              text
              :disabled="loadingAssign"
              @click="addPromo()"
            >
              Submit
            </v-btn><v-btn
            v-else
              color="primary"
              text
              :disabled="loadingAssign"
              @click="editPromo()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="dialogAssign = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
                                  v-model="dialog_basic"
                                  max-width="500px"
                                >
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5">{{ formTitle }}</span>
                                    </v-card-title>
  
                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                          >
                                            <v-text-field
                                              v-model="editedItemBasic.item"
                                              label="Enter Item"
                                            ></v-text-field>
                                          </v-col>
                                          
                                        </v-row>
                                      </v-container>
                                    </v-card-text>
  
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeBasic"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="saveBasic(selected_item)"
                                      >
                                        Save
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
  
      <v-dialog
          persistent
        v-model="dialogAddFA"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" v-if="!fa.id">
            Add new Focus Area
          </v-card-title>
          <v-card-title class="text-h5 grey lighten-2" v-else>
            Edit Focus Area
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
            <v-text-field
                  label="Name"
                  v-model="fa.name"
            ></v-text-field>
          
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            v-if="!fa.id"
              color="#4caf50"
              text
              :disabled="loadingAssign"
              @click="addFocusArea()"
            >
              Submit
            </v-btn><v-btn
            v-else
              color="primary"
              text
              :disabled="loadingAssign"
              @click="editFocusArea()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="dialogAddFA = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          persistent
        v-model="dialogAddL"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" v-if="!location.id">
            Add new Location
          </v-card-title>
          <v-card-title class="text-h5 grey lighten-2" v-else>
            Edit Location
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
  
            <vuetify-google-autocomplete
                                      id="map"
                                      v-model="location.name"
                                      label="Location"
                                      color="#e91e63"
                                       :rules="rules"
                                      class="font-size-input input-style"
                                      placeholder="Enter a location"
                                      v-on:placechanged="getAddressData"
                                      country="us"
                                  ></vuetify-google-autocomplete>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            v-if="!location.id"
              color="#4caf50"
              text
              :disabled="loadingAssign"
              @click="addLocation()"
            >
              Submit
            </v-btn><v-btn
            v-else
              color="primary"
              text
              :disabled="loadingAssign"
              @click="editLocation()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="dialogAddL = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          persistent
        v-model="dialogCustomLink"
        width="500"
      >
  
        <v-card>
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
          <v-card-title class="text-h5 grey lighten-2" v-if="editFlag">
            Edit custom menu link 
          </v-card-title>
          <v-card-title class="text-h5 grey lighten-2" v-else>
            Create a custom menu link 
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
            <span style="color:#5D6167;">Link title</span>
            <v-text-field
                  v-model="link.title"
                  required
                  :rules="nameRules"
                  solo
            ></v-text-field>
            <!-- <span style="color:#5D6167;">Link icon</span>
            <v-dialog
              v-model="showDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="700px"
              max-height="850px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="icon"
                  :prepend-inner-icon="selectedIcon"
                  :label="selectedIcon"
                  v-bind="attrs"
                  v-on="on"
                  color="deep-purple"            
                  readonly
                  solo  
                >
                </v-text-field>
              </template>
              <v-card>
                <v-app-bar
                  color="white"
                  scroll-target="#scrolling-techniques-7"
                  elevation="0"
                >
                  <v-text-field
                    v-model="searchIcon"
                    placeholder="Search icon"
                    prepend-icon="mdi-magnify"
                    type="text"
                    onautocomplete="off"
                    dense
                  />
                </v-app-bar>
                <v-sheet
                  id="scrolling-techniques-7"
                  class="overflow-y-auto"
                  max-height="600"
                >
                  <v-col cols="12">
                    <v-btn
                      v-for="(item, i) in allIcons"
                      :key="i"
                      @click="saveIcon(item)"
                      color="white"
                      class="mr-2 mb-2 "
                      fab
                      small
                      depressed
                    >
                      <v-icon color="grey darken-3"> mdi-{{ item }} </v-icon>
                    </v-btn>
                  </v-col>
                  
                </v-sheet>
              </v-card>
            </v-dialog> -->
            <span style="color:#5D6167;">URL</span>
            <v-text-field
                  v-model="link.url"
                  required
                  :rules="nameRules"
                  solo
            ></v-text-field>
            <v-switch
            label="Show link on sidebar"
              v-model="link.sidebar"
              inset
            ></v-switch>
            <v-switch
            label="Show to all accounts"
              v-model="link.all_account"
              inset
            ></v-switch>
            <v-select
            v-if="link.all_account == 0"
              v-model="link.list_account"
              :items="companies"
              chips
              item-text="name"
              item-value="id"
              label="Chips"
              multiple
              solo
            ></v-select>
            <v-switch
            label="Show to Dashboard"
              v-model="link.dashboard"
              inset
            ></v-switch>
            <v-switch
            label="Show to Client Profile"
              v-model="link.client"
              inset
            ></v-switch>
            <v-radio-group
              v-model="link.is_iframe"
              row
            >
              <v-radio
                label="Open in iFrame"
                :value="1"
              ></v-radio>
              <v-radio
                label="Open in a New Tab"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              v-if="!editFlag"
              :disabled="loadingCustomLink"
              @click="addLink()"
            >
              Submit
            </v-btn>
            <v-btn
              color="primary"
              text
              v-if="editFlag"
              :disabled="loadingCustomLink"
              @click="editLink()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingCustomLink"
              @click="dialogCustomLink = false"
            >
              Close
            </v-btn>
          </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteBasic" max-width="500px">
                                  <v-card>
                                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="blue darken-1" text @click="closeDeleteBasic">Cancel</v-btn>
                                      <v-btn color="blue darken-1" text @click="deleteItemConfirmBasic(selected_item)">OK</v-btn>
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
      <v-dialog
          persistent
        v-model="dialogCoupon"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" v-if="!promo.id">
            Add new Coupon code
          </v-card-title>
          <v-card-title class="text-h5 grey lighten-2" v-else>
            Edit Coupon code
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
            <v-text-field
            label="Name"
                  v-model="promo.name"
                  :disabled="promo.id ? true : false"
            ></v-text-field>
                   <v-select
                              label="Type"
                              placeholder="Select type"
                              :disabled="loadingAssign"
                              v-model="promo.type"
                              :items="['Percentage','Fixed']"
                              ></v-select>
            <v-text-field
            label="Discount"
            v-if="promo.type == 'Fixed'"
                  v-model="promo.value"
                  prefix="$"
                  v-mask="'###########################'"
            ></v-text-field>
                <v-text-field
                label="Discount"
            v-if="promo.type == 'Percentage'"
                  v-model="promo.value"
                  suffix="%"
                  v-mask="'###'"
            ></v-text-field>
                   <v-select
                   label="Usage Limit"
                              placeholder="Select Limit"
                              v-model="promo.count"
                              :items="['Unlimited','1','2','5','10','15']"
                              ></v-select>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              v-if="!promo.id"
              :disabled="loadingAssign"
              @click="addCoupon()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              v-else
              :disabled="loadingAssign"
              @click="editCoupon()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="dialogCoupon = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-container>
      <vue-footer-admin></vue-footer-admin>
      </v-main>
  </v-app>
  
  </template>
  
  <script>
  import { VueTelInput } from 'vue-tel-input'
  import HtmlEditor from "../HtmlEditor.vue";
  import Sortable from 'sortablejs';
  import Editor from '@tinymce/tinymce-vue'
  export default {
  components: {
        VueTelInput,HtmlEditor,Editor
    },
    data () {
  
        return {
          fname : '',
          lname : '',
          email : '',
          id: '',
          name:'',
          preview : '',
          editedItemBasic: {
            item: '',
          },
          loader_logo : false,
          loader_sub: false,
          showPlaceholder: false,
          payment_gateway_options: [],
          phone:'',
          password : '',
          users : [],
          nameRules: [
          v => !!v || 'Field is required'
        ],
          valid: true,
          page:1,
          promo: {
            name : '',
            type : 'Percentage',
            value : '',
            count : 1,
          },
          itemsPerPage:10,
          confpassword : '',
          dialogCoupon : false,
          role : '',
          headers_letter : [
             { text: 'Title', value: 'title',
              sortable: true },
            { text: 'Category', value: 'category',
              sortable: true },
            { text: 'Staus', value: 'status',
              sortable: true },
            { text: 'Created at', value: 'created_at',
              sortable: true,width: '200' },
            { text: 'Updated at', value: 'updated_at',
              sortable: true,width: '200' },
              { text: 'Edit', value: 'edit',
              sortable: true,width: '200' },
            { text: 'Delete', value: 'id',
              sortable: false,width: '50' },
          ],
          headers_fa : [
             { text: 'Name', value: 'name',
              sortable: true },
            { text: 'Created at', value: 'created_at',
              sortable: true,width: '200' },
            { text: 'Updated at', value: 'updated_at',
              sortable: true,width: '200' },
              { text: 'Edit', value: 'edit',
              sortable: true,width: '200' },
            { text: 'Delete', value: 'id',
              sortable: false,width: '50' },
          ],
           headers_l : [
             { text: 'Name', value: 'name',
              sortable: true },
            { text: 'Created at', value: 'created_at',
              sortable: true,width: '200' },
            { text: 'Updated at', value: 'updated_at',
              sortable: true,width: '200' },
              { text: 'Edit', value: 'edit',
              sortable: true,width: '200' },
            { text: 'Delete', value: 'id',
              sortable: false,width: '50' },
          ],
          datatable_options_letter: {},
          datatable_options_fa: {},
          datatable_options_l: {},
          dialogAddFA : false,
          dialogAddL : false,
          headers_coupons : [  { text: 'Name', value: 'name',
              sortable: true },
            { text: 'Type', value: 'type',
              sortable: true },
            { text: 'Value', value: 'value',
              sortable: true },
               { text: 'Usage Limit', value: 'count',
              sortable: true },
            { text: 'Created at', value: 'created_at',
              sortable: true,width: '200' },
            { text: 'Updated at', value: 'updated_at',
              sortable: true,width: '200' },
              { text: 'Edit', value: 'edit',
              sortable: true,width: '200' },
            { text: 'Delete', value: 'id',
              sortable: false,width: '50' },],
          datatable_options_coupons : {},
          coupons : [],
          page_coupons : 1,
          page_fa : 1,
          page_l : 1,
          itemsPerPage_coupons :10,
          page_letter : 1,
          itemsPerPage_letter: 10,
          dialogAssign: false,
          pageCount: 1,
          fa : {
            name : '',
            id : ''
          },
          location : {
            name : '',
            id : ''
          },
          pageCountLetter : 1,
          pageCountCoupons : 1,
          pageCountFA : 1,
          pageCountL : 1,
          pageLink:1,
          pageCountLink: 1,
          logo : '',
          totalCount: 0,
          totalCountLink: 0,
          companyname : '',
          previewbg:'',
          dialogDeleteBasic :  false,
          focus_area : [],
          headers_basic: [
            { text: 'Item', value: 'item' },
            { text: 'Actions', value: 'actions', sortable: false },
          ],
          dialog_basic: false,
          locations : [],
          sector : '',
          no_of_employee : '',
          showPassword : false,
          loadingAssign: false,
          limit: 10,
          limit_letter: 10,
          totalCountLetter : 0,
   
          totalCountFA : 0,
          totalCountL : 0,
          letters : [],
          dialogCustomLink: false,
          loadingCustomLink: false,
          phoneFlag:0,
          emailFlag:0,
          link: {
            title:'',
            icon: 'mdi-web',
            url: '',
            is_iframe: 1,
            sidebar:1,
            all_account: 1,
            list_account:[],
            dashboard : 0,
            client: 0
          },
          product: {
            credit: 0,
            price: 0,
            type :'Company',
            items : [],
            name : ''
          },
          country :'',
          datatable_options:{},
          otp_email:'',
          otp_phone:'',
          links: [],
          otp_phone_input:"",
          otp_email_input:"",
          user_phone:'',
          editFlag: false,
          companies: [],
          user_email:'',
          headers_jobs: [
            { text: 'Price', value: 'price',
              sortable: true },
            { text: 'Credit', value: 'credit',
              sortable: true },
            { text: 'Type', value: 'type',
              sortable: true },
            { text: 'Created at', value: 'created_at',
              sortable: true,width: '200' },
            { text: 'Updated at', value: 'updated_at',
              sortable: true,width: '200' },
              { text: 'Edit', value: 'edit',
              sortable: false },
            { text: 'Delete', value: 'id',
              sortable: false,width: '50' },
          ],
          headers_links: [
            { text: 'Title', value: 'title',
              sortable: false },
            { text: 'URL', value: 'url',
              sortable: false },
            { text: 'Open Mode', value: 'is_iframe',
              sortable: false },
            { text: 'Created at', value: 'created_at',
              sortable: false,width: '200' },
            { text: 'Updated at', value: 'updated_at',
              sortable: false,width: '200' },
              { text: 'Edit', value: 'edit',
              sortable: false,width: '200' },
            { text: 'Delete', value: 'id',
              sortable: false,width: '50' },
          ],
          status:0,
          settings :{
              payment_gateway: '',
              test_mode : 1,
              grant_count: 10,
              list_account : [],
              backgroung_logo: '',
              display_to : 'All',
              pricing :{
                  user_credit : "",
              }
  
          },
          tab: null,
          showDialog: false,
        icon: '',
        searchIcon: '',
        selectedIcon: 'mdi-check-outline',
          
        }
      },
      directives: {
      sortableDataTable: {
        bind (el, binding, vnode) {
          const options = {
            animation: 150,
            onUpdate: function (event) {
              vnode.child.$emit('sorted', event)
            }
          }
          Sortable.create(el.getElementsByTagName('tbody')[0], options)
        }
      }
    },
      methods: {
        saveOrder (event) {
        const movedItem = this.users.splice(event.oldIndex, 1)[0];
        this.users.splice(event.newIndex, 0, movedItem);
         axios({
            method: "POST",
             
            url: "/api/save-product-order",
            data : this.users,
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
          })
          .then(response => {
              console.log('product order saved');
  
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      saveOrderLink (event) {
        const movedItem = this.links.splice(event.oldIndex, 1)[0];
        this.links.splice(event.newIndex, 0, movedItem);
         axios({
            method: "POST",
             
            url: "/api/save-link-order",
            data : this.links,
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
          })
          .then(response => {
              console.log('product order saved');
  
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      openAdd(){
        this.link = {
            title:'',
            icon: 'mdi-web',
            url: '',
            is_iframe: 1,
            sidebar:1,
            all_account: 1,
            list_account:[],
          };
        this.dialogCustomLink = true;
      },
      openEdit(item){
        
        this.editFlag = true;
        this.link = item;
        this.link.list_account = Array.isArray(this.link.list_account) ? this.link.list_account : JSON.parse(this.link.list_account)
        console.log(this.link);
        this.selectedIcon = item.icon;
        this.dialogCustomLink = true;
      },
      getSettings() {
      let flag = 1;
      let url =process.env.VUE_APP_WASBI_URL
      var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
        axios({
            method: "POST",
             
            url: "/api/get-company-settings",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
          })
          .then(response => {
              let data = response.data.data;
              if(data){
                this.id = data.id;
                this.preview = data.logo;
                this.settings = data.settings;
                
                this.previewbg = url+data.settings.backgroung_logo
              }
  
  
           })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
  
       },
       productEditOpen(item){
        this.product = item
        this.product.items = this.product.items ? Array.isArray(this.product.items) ? this.product.items :JSON.parse(this.product.items) : []
        this.dialogAssign=true
       },
       previewImage: function(event) {
        var input = event.target;
        if (input.files) {
          this.logo = input.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview = e.target.result;
          }
          console.log(this.preview);
          reader.readAsDataURL(input.files[0]);
        }
      },
       previewImageBG: function(event) {
        var input = event.target;
        console.log(input);
        if (input.files) {
          this.settings.backgroung_logo = input.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            this.previewbg = e.target.result;
          }
          this.image=input.files[0];
          reader.readAsDataURL(input.files[0]);
        }
      },
       getAllCompanies(){
              let headers = {
                  Authorization: "Bearer " + localStorage.getItem('token')
              }
              axios
              .get("/api/get-all-company", {headers: headers})
              .then(response => {
                  this.companies = response.data.data;
                  this.companies.push({name: 'None',id:'None'})
              })
          },
      deleteItem(id){
         
            var r = confirm("Are you sure, You went to delete?");
            if (r == true) {
              axios
                .get("/api/delete-promo/"+id,this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getProducts();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          deleteItemLocation(id){
         
            var r = confirm("Are you sure, You went to delete?");
            if (r == true) {
              axios
                .get("/api/delete-location/"+id,this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getL();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          deleteItemConfirmBasic (plan) {
          this.product.items.splice(this.editedIndexBasic, 1)
          this.closeDeleteBasic()
        },
          deleteItemFA(id){
         
            var r = confirm("Are you sure, You went to delete?");
            if (r == true) {
              axios
                .get("/api/delete-focus-area/"+id,this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getFA();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          deleteItemLetter(id){
         
            var r = confirm("Are you sure, You went to delete?");
            if (r == true) {
              axios
                .post("/api/delete-letter/"+id,this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getLetters();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          deleteItemLink(id){
         
            var r = confirm("Are you sure, You went to delete?");
            if (r == true) {
              axios
                .get("/api/delete-link/"+id,this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getLinks();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          deleteItemBasic (flag,item) {
          this.editedIndexBasic = this.product.items.indexOf(item)
          this.editedItemBasic = Object.assign({}, item)
          this.dialogDeleteBasic = true
        },
          closeBasic () {
          this.dialog_basic = false
          this.$nextTick(() => {
            this.editedItemBasic = Object.assign({}, {item : ''})
            this.editedIndexBasic = -1
          })
        },
          getAddressData: function (addressData, placeResultData, id) {
              this.location.name = addressData.name;
          },
          editItemBasic (flag,item) {
          this.editedIndexBasic = this.product.items.indexOf(item)
          this.editedItemBasic = Object.assign({}, item)
          this.dialog_basic = true
        },
          saveBasic (item) {
          if (this.editedIndexBasic > -1) {
            Object.assign(this.product.items[this.editedIndexBasic], this.editedItemBasic)
          } else {
            this.product.items.push(this.editedItemBasic)
          }
          this.closeBasic()
        },
          deleteItemCoupon(id){
         
            var r = confirm("Are you sure, You went to delete?");
            if (r == true) {
              axios
                .get("/api/delete-coupon/"+id,this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getCoupons();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
      getPayments(){
              this.loading = true;
             
              axios({
            method: "GET",
             
            url: "/api/get-all-payment-gateway",
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
              this.payment_gateway_options = response.data.data
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
      addPromo(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/add-promo",
            data: this.product
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Product added successfully', {
                        position: 'top-right'
                        });
                        this.dialogAssign = false;
                        this.loadingAssign = false;
                        this.getProducts();
                        this.product.price = '';
                        this.product.credit = '';
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        addFocusArea(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/add-focus-area",
            data: this.fa
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Focus Area added successfully', {
                        position: 'top-right'
                        });
                        this.dialogAddFA = false;
                        this.loadingAssign = false;
                        this.getFA();
                        this.fa.name = '';
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        removeLogo(){
        if(confirm('Are you sure? you want to remove.')){
        axios({
          method: "POST",
          url: "/api/remove-logo",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {id: this.id}
          })
          .then(response => {
          Vue.$toast.success("Logo removed", {
              position: 'top-right'
              });
              localStorage.setItem('company_logo','')
              this.preview = ''
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
        }
      
    },
        addLocation(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/add-location",
            data: this.location
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Location added successfully', {
                        position: 'top-right'
                        });
                        this.dialogAddL = false;
                        this.loadingAssign = false;
                        this.getL();
                        this.location.name = '';
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        addCoupon(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/add-coupon-codes",
            data: this.promo
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Coupon added successfully', {
                        position: 'top-right'
                        });
                        this.dialogCoupon = false;
                        this.loadingAssign = false;
                        this.getCoupons();
                        this.promo = {
                              name : '',
                              type : 'Percentage',
                              value : '',
                              count : 1
                            };
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        editCoupon(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/edit-coupon/"+this.promo.id,
            data: this.promo
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Coupon edited successfully', {
                        position: 'top-right'
                        });
                        this.dialogCoupon = false;
                        this.loadingAssign = false;
                        this.getCoupons();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        editPromo(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/edit-promo/"+this.product.id,
            data: this.product
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Product edited successfully', {
                        position: 'top-right'
                        });
                        this.dialogAssign = false;
                        this.loadingAssign = false;
                        this.getProducts();
                        this.product.price = '';
                        this.product.credit = '';
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        editFocusArea(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/edit-focus-area/"+this.fa.id,
            data: this.fa
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Focus Area edited successfully', {
                        position: 'top-right'
                        });
                        this.dialogAddFA = false;
                        this.loadingAssign = false;
                        this.getFA();
                        this.fa.name = '';
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        uploadLogo(){
          const data = new FormData();
            data.append('file_logo', this.logo);
            data.append('id',this.id);
            this.loader_logo = true;
            axios({
              method: "POST",
              url: "/api/upload-logo",
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
              data: data
              })
              .then(response => {
              Vue.$toast.success("Logo uploaded", {
                  position: 'top-right'
                  });
                  localStorage.setItem('company_logo',response.data.data)
              this.loadingUpload = false;
              this.loader_logo = false;

              })
              .catch(err => {
                  Vue.$toast.error("Invalid file to upload", {
                  position: 'top-right'
                  });
                  this.isDisableUpload = false;
                  this.isDarkUpload = true;
              });
          
        },
        uploadLogoBG(){
        const data = new FormData();
        let url =process.env.VUE_APP_WASBI_URL
          data.append('file_logo', this.settings.backgroung_logo);
          axios({
            method: "POST",
            url: "/api/upload-logo-background-admin",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: data
            })
            .then(response => {
            Vue.$toast.success("Logo uploaded", {
                position: 'top-right'
                });
                console.log();
                this.settings.backgroung_logo = response.data.data
                this.saveSettings()
                localStorage.setItem('admin_bg',url+response.data.data)
            this.loadingUpload = false;
  
            })
            .catch(err => {
                Vue.$toast.error("Invalid file to upload", {
                position: 'top-right'
                });
                this.isDisableUpload = false;
                this.isDarkUpload = true;
            });
        
      },
        editLocation(){
          this.loadingAssign = true;
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/edit-location/"+this.location.id,
            data: this.location
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Location edited successfully', {
                        position: 'top-right'
                        });
                        this.dialogAddL = false;
                        this.loadingAssign = false;
                        this.getL();
                        this.location.name = '';
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                position: 'top-right'
                });
                this.loadingAssign = false;
                })
            
              
        },
        addLink(){
          if(this.$refs.form.validate()){
          this.loadingCustomLink = true;
          let req = this.link;
          req.icon = this.selectedIcon;
          req.list_account = JSON.stringify(req.list_account)
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/add-cusom-link",
            data: req
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Custom Link added successfully', {
                        position: 'top-right'
                        });
                        this.dialogCustomLink = false;
                        this.loadingCustomLink = false;
                        this.getLinks();
                        this.link.title = '';
                        this.link.url= '';
                        this.link.list_account= [];
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
                this.loadingCustomLink = false;
                })
          }
            
              
        },
        editLink(){
          if(this.$refs.form.validate()){
          this.loadingCustomLink = true;
          let req = this.link;
          req.icon = this.selectedIcon;
          req.list_account = JSON.stringify(req.list_account)
          axios({
            method: "POST",
           headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
             
            url: "/api/edit-custom-link",
            data: req
          })
          
            .then(response => {
                let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Custom Link edited successfully', {
                        position: 'top-right'
                        });
                        this.dialogCustomLink = false;
                        this.loadingCustomLink = false;
                        this.getLinks();
                        this.link.title = '';
                        this.link.url= '';
                        this.link.list_account= [];
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
                .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
                this.loadingCustomLink = false;
                })
          }
            
              
        },
      getProducts(){
        this.loading = true;
              this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
              this.selected_project = name;
              let req = {
                limit : this.datatable_options.itemsPerPage,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search
              }
              axios({
            method: "POST",
             
            url: "/api/get-promo-codes",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.users = data.data;
                  this.pageCount = data.total_page
                  this.totalCount = data.count
                  console.log( this.users);
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      getFA(){
        this.loading = true;
              this.offset = (this.page - 1) * this.datatable_options_fa.itemsPerPage
              this.selected_project = name;
              let req = {
                limit : this.datatable_options.itemsPerPage,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : ''
              }
              axios({
            method: "POST",
             
            url: "/api/get-focus-area",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.focus_area = data.data;
                  this.pageCountFA = data.total_page
                  this.totalCountFA = data.count
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      getL(){
        this.loading = true;
              this.offset = (this.page - 1) * this.datatable_options_l.itemsPerPage
              this.selected_project = name;
              let req = {
                limit : this.datatable_options.itemsPerPage,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : ''
              }
              axios({
            method: "POST",
             
            url: "/api/get-location",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.locations = data.data;
                  this.pageCountL = data.total_page
                  this.totalCountL = data.count
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      getCoupons(){
        this.loading = true;
              this.offset = (this.page - 1) * this.datatable_options_coupons.itemsPerPage
              this.selected_project = name;
              let req = {
                limit : this.datatable_options_coupons.itemsPerPage,
                offset : this.offset,
                sort : this.datatable_options_coupons.sortBy,
                sortDesc : this.datatable_options_coupons.sortDesc && this.datatable_options_coupons.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search
              }
              axios({
            method: "POST",
             
            url: "/api/get-coupon-codes",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.coupons = data.data;
                  this.pageCountCoupons = data.total_page
                  this.totalCountCoupons = data.count
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      getLinks(){
        this.loading = true;
              this.offset = (this.pageLink - 1) * 10
              this.selected_project = name;
              let req = {
                limit : -1,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search
              }
              axios({
            method: "POST",
             
            url: "/api/get-custom-links",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                  this.links = data.data;
                  this.pageCountLink = data.total_page
                  this.totalCountLink = data.count
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      getLetters(){
        this.loading = true;
              var offset = (this.page_letter - 1) * 10
              let req = {
                limit : this.limit_letter,
                offset : offset,
                sort : this.datatable_options_letter.sortBy,
                sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search_letter
              }
              axios({
            method: "POST",
             
            url: "/api/get-all-letter",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                  this.letters = data.data;
                  this.pageCountLetter = data.total_page
                  this.totalCountLetter = data.count
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
      },
      closeDeleteBasic () {
          this.dialogDeleteBasic = false
          this.$nextTick(() => {
            this.editedItemBasic = Object.assign({}, {item:''})
            this.editedIndexBasic = -1
          })
        },
      saveSettings: function(e) {
        this.loader_sub = true
          axios({
            method: "POST",
             
            url: "/api/update-company-settings",
            data: {
              id: this.id,
              settings: this.settings
            }
          })
            .then(response => {
              let data = response.data;
              if (response.data) {
                  this.loader_sub = false
                    Vue.$toast.success('Settings saved', {
                      position: 'top-right'
                    })
                    this.getSettings()
              } else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }
            })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            });
      },
      saveIcon: function(icon) {
        this.icon = icon;
        this.selectedIcon = "mdi-" + icon;
        this.showDialog = false;
      }
  
   },
   
  watch: {
    dialogDeleteBasic (val) {
          val || this.closeDeleteBasic()
        },
      searchIcon: function(oldSearchIcon, newSearchIcon) {
      let filteredIcons = [];
      if (this.searchIcon.length === 0) {
        filteredIcons = this.allIcons;
      } else {
        filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
      }
      this.allIcons = filteredIcons;
    },
    datatable_options: {
                  handler () {
                      console.log(this.datatable_options);
                  this.getProducts()
                  },
                  deep: true,
              },
    datatable_options_coupons : {
      handler () {
                  this.getCoupons()
                  },
                  deep: true,
    },
    datatable_options_fa : {
      handler () {
                  this.getFA()
                  },
                  deep: true,
    },
    datatable_options_l : {
      handler () {
                  this.getL()
                  },
                  deep: true,
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push('/login')
      }else{
        console.log('created called.');
        this.getSettings();
        // this.getProducts();
        // this.getLinks()
        // this.getAllCompanies();
        // this.getLetters();
        // this.getPayments();
      }
        
      },
  
  }
  </script>
  
  <style>
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }
  .v-input--switch__track {
    color: #4caf50 !important;
    caret-color: #4caf50;
  }
  .v-input--switch__thumb {
    color: #4caf50 !important;
    caret-color: #4caf50;
  }
  </style>