<template>
    <v-app class="bg-white">
    <vue-header-no-login></vue-header-no-login>
    <v-main class="auth-pages mt-10">
        <v-row  style="margin-top: 100px;margin-right: 0px;">
        <v-col cols="12" md="12" lg="12">
          <v-container class="pb-0 mt-10 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
            <h1 class="text-h2 font-bold underline">About PASS Profile
            </h1>
            <p class="text-2xl mt-5">At PASS Profile LLC, we believe in the power of communication as the cornerstone of personal and professional success. Our Personal Authentic Speaking Style Profile (PASS Profile) is a pioneering tool designed to uncover and enhance individual communication styles. Developed through rigorous research and expert insights, PASS Profile helps individuals understand their unique speaking styles, empowering them to communicate more effectively in various settings. Whether you're looking to improve interpersonal relationships, enhance workplace dynamics, or refine public speaking skills, PASS Profile provides personalized insights and practical advice to help you succeed. Join us in unlocking the potential of your authentic voice.
            </p>
            </v-container>
            </v-col>
            </v-row>
</v-main>
</v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>