<template>
  <div id="app">
       <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import Utility from "@/components/common/functions.js";

export default {
  name: "app",
  data: () => ({
    // title: title,
    utility: Utility,
    drawer: false
  }),
  methods: {
    logout() {
      axios
        .post("/logout")
        .then(response => {
          this.$router.push("/");
          window.location.reload();
        })
        .catch(error => {
          location.reload();
        });
    },
    checkSession(){
        let self = this;
        let token = localStorage.getItem('token');
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        let avatar_url = process.env.VUE_APP_WASBI_URL
        axios.get('/api/user', header ).then(function (result) {
                if(result.data.status == 'Token is Invalid' || result.data.status == 'Token is Expired' || result.data.status == 'Authorization Token not found' || (localStorage.getItem('is_admin') != result.data.user.is_admin)){
                    console.log('session destroyed');
                    localStorage.removeItem("token")
                    localStorage.removeItem("user_id")
                    localStorage.removeItem("email")
                    localStorage.removeItem("role")
                    localStorage.removeItem("name")
                    localStorage.removeItem("is_admin")
                    self.$router.push('/login')
                    self.$router.go();
                }else{
                  localStorage.setItem('credit',result.data.user.credit);
                  let preview = result.data.user.avatar ? result.data.user.avatar : ''
                  localStorage.setItem('avatar',preview);
                }
            }).catch(function (error) {
                self.$router.push('/login')
                console.log("session expired");
        })
    },
    getDashoardCounts(){
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios.get('/api/getdashboard', header ).then(function (result) {
                console.log(result);
                localStorage.setItem('count_user', result.data.data.count_user)
                localStorage.setItem('count_total_booking', result.data.data.count_total_booking)
                localStorage.setItem('count_total_reserved', result.data.data.count_total_reserved)
                localStorage.setItem('count_parking', result.data.data.count_parking)
            }).catch(function (error) {
                console.log("session expired");
        })
    },
    getSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
               localStorage.setItem('settings', JSON.stringify(data.settings));
               localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });

     },
  getClientSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "GET",
          url: "/api/get-client-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              let settings = JSON.parse(data.settings)
               localStorage.setItem('welcome_video', settings.welcome_video);
               localStorage.setItem('welcome_vido_list_client', settings.list_client);
               localStorage.setItem('welcome_video_url', url+settings.welcome_video_url);
              //  localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
            }else{
              localStorage.setItem('welcome_video', 'None');
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
          },
          getDomain() {
              let flag = 1;
              let url = process.env.VUE_APP_WASBI_URL
              var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
                axios({
                    method: "GET",
                    url: "/api/get-domain-settings",
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                  })
                  .then(response => {
                      let data = response.data.data;
                      if(data.flag == 1){
                        let settings = JSON.parse(data.settings)
                        // localStorage.setItem('welcome_video', settings.welcome_video);
                      }


                  })
                    .catch(err => {
                      // Vue.$toast.error(err, {
                      // position: 'top-right'
                      // });

                    });
          }

  },
  mounted: function () {
    let script = document.createElement("script");
      script.src =  "https://js.stripe.com/v2/";
      document.head.appendChild(script);
      let script2 = document.createElement("script");
      // script2.src =  "https://code.jivosite.com/widget/8Et8xacX3Z";
      // document.head.appendChild(script2);
    let role = JSON.parse(localStorage.getItem('role'));
    if(localStorage.getItem('token')){
      // if(localStorage.getItem('agreement_flag') == 0 && localStorage.getItem('user_id') != 1 && role.name != 'client')
      //   this.$router.push("/admin/agreement");
      this.checkSession()
      // this.getSettings();
      // this.getClientSettings()
      // this.getDomain()
      // this.getDashoardCounts();
    }else{
      // this.getSettings();
      //  this.$router.push("/");
    }
  },
};
</script>
