<template>
  <v-app >
  <vue-header v-if="!modalFlag"></vue-header>
      <v-main style="padding:0px!important;">
      <v-container style="margin-top:200px;margin-bottom: 72px;">
        <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                My Pass Profiles
              </h5>
              <p class="text-sm font-weight-light text-body mb-0">
                Here is the list of all request submitted.
              </p>
            </div>
            <v-btn
                  elevation="0"
                  @click="$router.push('/survey')"
                  :ripple="false"
                  height="43"
                  color="#4CAF50"
                  class="
                    font-weight-normal
                    text-capitalize
                    ms-auto
                    btn-primary
                    py-3
                    px-6
                    ms-3
                  "
                  >New Pass Profile</v-btn
                >
          </div>
        </div>
  
        <v-card-text class="px-0 py-0">
          <v-data-table
            
            :headers="headers_jobs"
                              :options.sync="datatable_options"
                              :items="users"
                              loading-text="Loading... Please wait"
                              :page.sync="page"
                              :items-per-page="itemsPerPage"
            :loading="loading"
  
  
            :search="search"
            class="table"
            hide-default-footer
            @page-count="pageCount = $event"
            mobile-breakpoint="0"
          >
            <template v-slot:item.status="{ item }">
              <div class="d-flex align-center">
                <v-btn
                  :ripple="false"
                  icon
                  outlined
                  rounded
                   v-if="item.status == 'Inprogress'"
                  width="25px"
                  height="25px"
                  class="me-2"
                  :class="'border-ranger'
                  "
                >
                  <v-icon
                    size="8"
                    :class="'text-danger'
                    "
                  >
                fa fa-pause
                  </v-icon>
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  outlined
                  rounded
                   v-if="item.status == 'Completed'"
                  width="25px"
                  height="25px"
                  class="me-2"
                  :class="'border-success'
                  "
                >
                  <v-icon
                    size="8"
                    :class="'text-success'
                    "
                  >
                fas fa-check
                  </v-icon>
                </v-btn>
                <v-btn
                  :ripple="false"
                  icon
                  outlined
                  rounded
                   v-if="item.round_1_key || item.round_2_key || item.round_3_key || item.round_4_key"
                  width="25px"
                  height="25px"
                  class="me-2"
                  :class="'border-success'
                  "
                >
                  <v-icon
                    size="10"
                    :class="'text-success'
                    "
                  >
                fa fa-hourglass-start
                  </v-icon>
                </v-btn>
                <span
                                                                v-if="item.status == 'Inprogress'"
                                                                style="color:red;"
                                                              >
                                                              Inprogress
                                                              </span>
                                                              <span
                                                               
                                                                v-if="item.status == 'Completed'"
                                                                style="color:green;"
                                                              >
                                                              Finished
                                                              </span>
                                                             
              </div>
            </template>
            <template v-slot:item.created_at="{ item }" style="text-align:center;">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.updated_at="{ item }" style="text-align:center;">
              {{ formatDate(item.updated_at)}}
            </template>
            <template v-slot:item.process1="{ item }" style="text-align:center;">
                            
                            <v-btn
                              class="mx-2"
                              dark
                              @click="download(item.id)"
                              x-small
                              :color="'#3684C5'"
                            >
                            Download
                            </v-btn>
                           
                          </template>
          </v-data-table>
        </v-card-text>
         <v-card-actions class="card-padding">
          <v-row>
            <v-col cols="6" lg="3" class="d-flex align-center">
              <span class="text-body me-3 text-sm">Items per page:</span>
              <v-text-field
                hide-details
                type="number"
                outlined
                min="-1"
                max="15"
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                :value="itemsPerPage"
                @input="itemsPerPage = parseInt($event, 10)"
                placeholder="Items per page"
                class="
                  font-size-input
                  placeholder-lighter
                  text-color-light
                  input-alternative input-focused-alternative input-icon
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="ml-auto d-flex justify-end">
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#4CAF50"
                 v-model="page"
                :length="pageCount"
                @input="getUsers"
                circle
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-actions>
        </v-card>
        </v-container>
      
          <v-dialog
            v-model="dialogDoc"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Process 1
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <span class="text-h5" >
                  <v-btn
                              class="mx-2"
                              dark
                              @click="StartProcess1(1)"
                              :color="'green'"
                            >
                            Server 1
                            </v-btn>
                </span>
              </v-card-text>
              <v-card-text >
                <span class="text-h5" >
                  <v-btn
                              class="mx-2"
                              dark
                              @click="StartProcess1(2)"
                              :color="'green'"
                            >
                            Server 2
                            </v-btn>
                </span>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogDoc = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
           <v-dialog
            v-model="process_status_dialog"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Transaction {{transaction.Key}}
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <span class="text-h5" >
                  <b>Status: </b>
                </span>
                <span class="text-h5" v-if="!transaction.ProcessingException" >
                  {{transaction.Status}}
                </span>
                <span class="text-h5" v-else >
                  {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
                </span>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="process_status_dialog = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
          persistent
        v-model="dialogImport"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Submit new request
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
             <rotate-loader class="mt-3 mb-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
                  <span style="color:#5D6167;">Process</span>
                   <v-select
                              outlined
                              :disabled="loadingUpload"
                              v-model="request.process"
                              :items="itemOptions"
                              ></v-select>
                  <span style="color:#5D6167;">Round</span>
                   <v-select
                              outlined
                              :disabled="loadingUpload"
                              v-model="request.round"
                              :items="[1,2,3,4]"
                              ></v-select>
                              <vue-recaptcha
                                  :sitekey="sitekey"
                                  :loadRecaptchaScript="true"
                                  ref="recaptcha"
                                  type="invisible"
                                  @verify="onCaptchaVerified"
                                  @expired="onCaptchaExpired"
                              >
                          </vue-recaptcha>
          </v-card-text>
  
          <v-divider></v-divider>
          <v-overlay
            :absolute="true"
            :value="creditFlag"
            :opacity=".5"
          >
            <v-btn
              color="blue"
               class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                          "
              @click="$router.push('/pricing')"
            >
              Buy Credit
            </v-btn>
            <v-btn
             class="
                          float-right
                            font-weight-bolder
                            btn-default
                            mb-5
                            px-8
                            ms-auto
                            mr-5
                          "
              color="black"
              @click="creditFlag = false,dialogImport = false"
            >
              Close
            </v-btn>
          </v-overlay>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingUpload"
              @click="submitRequest()"
            >
              Submit
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingUpload"
              @click="dialogImport = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      </v-container>
      
  </v-main>
  <vue-footer v-if="!modalFlag"></vue-footer>
  </v-app>
  
  </template>
  
  <script>
  import { VueTelInput } from 'vue-tel-input'
  import {Circle8} from 'vue-loading-spinner'
  import VueRecaptcha from 'vue-recaptcha'
  import moment from 'moment';
  import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
  export default {
      components : {
        'RotateLoader': ClipLoader,
        'vue-recaptcha' : VueRecaptcha
      },
      props : ['modalFlag'],
      data () {
        return {
          header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
          },
          rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 3 || 'Min 3 characters',
            emailMatch: () => (`The email and password you entered don't match`),
          },
          transaction : [],
          loading:true,
          dialog:false,
          initials:'',
          credit : localStorage.getItem('credit') ? localStorage.getItem('credit') : 0,
          dialogImport: false,
          valid:true,
          pageCount:1,
          company : '',
          sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          request: {
              process: "609",
              round: 1,
              recaptcha: ""
          },
          pageSort: {},
          itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft'],
          loadingUpload: false,
          documentList:[],
          totalCount:0,
          page:1,
          creditFlag: false,
          itemsPerPage:10,
          selected_job:'',
          search : "",
          dialogDoc:false,
          datatable_options:{},
          offset: 0,
          selected_id : '',
          limit: 10,
          validateCaptcha: false,
          selected_project:'',
          checkbox: '',
          process_status_dialog : false,
          headers_jobs: [
            { text: 'Name', value: 'name',
              sortable: true },
              { text: 'Email', value: 'email',
              sortable: true },
              { text: 'Phone', value: 'phone',
              sortable: true },
              // { text: 'Status', value: 'status',
              // sortable: true },
              // { text: 'CRC ID', value: 'crc_id',
              // sortable: false },
            { text: 'Submitted At', value: 'created_at',
              sortable: true,width: '200' },
              { text: 'Updated At', value: 'updated_at',
              sortable: true,width: '200' },
              { text: 'Status', value: 'status',
              sortable: false,width: '150' },
              { text: 'Process', value: 'process1',
              sortable: false,width: '150' },
              // { text: 'Process 1 Status', value: 'process_1_key',
              // sortable: false,width: '150' },
  
          ],
          projects: [
            
          ],
          users: []
        }
      },
      created () { 
          if(!localStorage.getItem('token'))
              this.$router.push('/login')
          
          this.getUsers();
          if(localStorage.getItem('company_id'))
            this.getUserCompany();
      },
      methods:{
        async download(id) {
            axios({
                method: "GET",
                url: "/api/download-passprofile/"+id,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                }).then(response => {
                  const downloadUrl = response.data.url;
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  link.setAttribute('target', '_blank'); // Open in new tab
                  link.setAttribute('download', this.fileName); // Optional: set file name
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                  })
                  .catch(function (error) {
                      console.log(error);
                  });

          
        },
          onCaptchaVerified: function (recaptchaToken) {
          this.request.recaptcha = recaptchaToken
          this.validateCaptcha = true
          },
          onCaptchaExpired: function () {
          this.$refs.recaptcha.reset();
          this.request.recaptcha = ''
          },
        dateFormat(date){
          return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
        },
          formatDate(value) {
              return moment(value).format("DD MMM YYYY HH:mm:ss");
          },
          StartProcess1(flag,id =''){
            console.log(id);
            let multiple_flag = 0;
            if(id == 'all')
              multiple_flag = 1
            var r = confirm("Are you sure, You went to start process 1?");
              if (r == true) {
  
                this.loadingUpload2 = true;
                this.dialogDoc = false;
                      axios
                  .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          Vue.$toast.success('Process started successfully', {
                              position: 'top-right'
                              });
                              this.loadingUpload2 = false;
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                              this.loadingUpload2 = false;
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          submitRequest(){
              let self = this;
              let flag = 1;
              if (!this.request.recaptcha) {
                  Vue.$toast.error('Captcha is required', {
                      position: 'top-right'
                  });
                  flag = 0;
              }
              
              if (flag == 1) {
                  
  
                  this.loadingUpload = true;
                  axios({
                  method: "POST",
                   
                  url: "/api/user-add-request",
                  data:self.request,
                  headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                  })
                  .then(response => {
                      console.log('suman',response.data.success);
                      if(response.data.success != "False"){
                          // self.$router.push('/thanks');
                          Vue.$toast.success('Request submitted', {
                              position: 'top-right'
                              }); 
                           
                              self.onCaptchaExpired()
                              self.loadingUpload = false;
                              self.dialogImport = false;
                              localStorage.setItem('credit', response.data.new_credit)
                              self.credit = response.data.new_credit;
                              self.getUsers();
                          // location.reload();
                      }else{
                          this.isDark = true;
                          this.isDisable = false;
                          
                          Vue.$toast.error(response.data.result.errors.error_message, {
                              position: 'top-right'
                              }); 
                      }
                  })
                  .catch(err => {
                      // console.log(JSON.parse(err.response.data));
                      this.isDark = true;
                      this.isDisable = false;
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                      self.onCaptchaExpired()
  
                  });
  
              }
          },
        getUserProfileParent() {
          let flag = 1;
          let self = this;
          self.loading = true;
          let header = { headers : {
                      Authorization:"Bearer " + localStorage.getItem('token')
                      }
              }
            axios
              .get("/api/user",header)
              .then(response => {
                  let data = response.data;
  
                if (flag == 1) {
                    this.user = data.user;
                    let settings = localStorage.getItem('settings');
                    settings = JSON.parse(settings);
                    let item = 1;
                    if(localStorage.getItem('company_id')){console.log(this.company);
                      if(parseInt(this.user.credit) < (parseInt(this.company.request_charge) * item)){
                        this.creditFlag = true;
                        this.dialogImport = true;
                      }else{
                        this.dialogImport = true;
                        this.creditFlag = false;
                      }
                    }else{
                      if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item)){
                        this.creditFlag = true;
                        this.dialogImport = true;
                      }else{
                        this.dialogImport = true;
                        this.creditFlag = false;
                      }
                    }
                    
                    self.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                      self.loading = false;
                  }
  
              })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
  
                });
  
          },
          getUserCompany() {
          let flag = 1;
          let self = this;
          let company = localStorage.getItem('company_id')
          self.loading = true;
          let header = { headers : {
                      Authorization:"Bearer " + localStorage.getItem('token')
                      }
              }
            axios
              .get("/api/get-company/"+company,header)
              .then(response => {
                  let data = response.data;
  
                this.company = data.data
  
              })
                .catch(err => {
                  // Vue.$toast.error(err, {
                  // position: 'top-right'
                  // });
  
                });
  
          },
          creditCheck(){
            this.getUserProfileParent();
          
  
          },
  
          getUsers(){
              this.loading = true;
              this.offset = (this.page - 1) * 10
              this.selected_project = name;
              let req = {
                limit : this.limit,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search
              }
              axios({
            method: "POST",
             
            url: "/api/get-pass-profiles",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.users = data.data;
                  this.pageCount = data.total_page
                  this.totalCount = data.count
                  if(this.totalCount == 0){
                    this.$router.push('/survey')
                  } 
                  console.log( this.users);
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              // Vue.$toast.error(err, {
              // position: 'top-right'
              // });
  
             });
          },
          getProcessStatus(id){
              this.loading = true;
              this.offset = (this.page - 1) * 10
              axios({
                method: "GET",
                 
                url: "/api/get-process-status/"+id,
                header : { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
                  }
              })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.transaction = data.data.value[0];
                  console.log(this.transaction);
                  this.process_status_dialog = true;
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
          
          
  
      },
      watch: {
              datatable_options: {
                  handler () {
                      console.log(this.datatable_options);
                  this.getUsers()
                  },
                  deep: true,
              },
          },
      
  }
  </script>
  
  