<template>
<v-app id="inspire" :style="role.name != 'client' ? 'background:#F9F9F9;' : ''">
<vue-header-admin v-if="role.name != 'client'"></vue-header-admin>
<vue-header v-if="role.name == 'client'"></vue-header>
    <v-main :style="role.name == 'client' ? 'padding:0px!important;' : ''">
    <v-container :style="role.name == 'client' ? 'margin-bottom: 72px;margin-top:200px;' : ''" >


       <v-card class="card-shadow border-radius-xl mt-5">
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Payment History
            </h5>
            <p class="text-sm font-weight-light text-body mb-0">
              Here is the list of all transactions.
            </p>
          </div>
          
        </div>
      </div>

      <v-card-text class="px-0 py-0">
                      
                        <v-data-table
                            :headers="role.name=='super_admin' ? headers_jobs_admin : headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            loading-text="Loading... Please wait"
                            v-model="selectedItems"
                            :page.sync="page"
                            class="table"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                        >
                        <template v-slot:item.history="{ item }">
                           <span style="color:green;" v-if="item.history.includes('+')">{{item.history}}</span><span style="color: red;" v-else>{{item.history}}</span> 
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.amount="{ item }">
                            {{item.amount ? formatCurrency(item.amount) : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                         <template v-slot:item.round_1_status="{ item }">
                          {{item.round_1_status ? item.round_1_status : '-'}}
                        </template>
                        <template v-slot:item.round_2_status="{ item }">
                          {{item.round_2_status ? item.round_2_status : '-'}}
                        </template>
                        <template v-slot:item.first_name="{ item }">
                          {{item.first_name ? item.first_name+' '+item.last_name+' ('+item.email+')' : '-'}}
                        </template>
                        <template v-slot:item.round_3_status="{ item }">
                          {{item.round_3_status ? item.round_3_status : '-'}}
                        </template>
                        <template v-slot:item.round_4_status="{ item }">
                          {{item.round_4_status ? item.round_4_status : '-'}}
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.process1="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.id"
                            x-small
                            v-if="!item.round_1_key && !item.round_2_key && !item.round_3_key && !item.round_4_key"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.round_4_status == 'Successful'"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.round_1_key || item.round_2_key || item.round_3_key || item.round_4_key"
                            :color="'green'"
                          >
                          Started
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            fab
                            v-if="!item.round_1_key && !item.round_2_key && !item.round_3_key && !item.round_4_key"
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon dark>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                         </v-card-text>
                       <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#4CAF50"
               v-model="page"
              :length="pageCount"
              @input="getUsers"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
                    
               
                </v-card>
        <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <v-dialog
          v-model="process_status_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Transaction {{transaction.Key}}
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <b>Status: </b>
              </span>
              <span class="text-h5" v-if="!transaction.ProcessingException" >
                {{transaction.Status}}
              </span>
              <span class="text-h5" v-else >
                {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="process_status_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-container>
    
</v-main>
<vue-footer v-if="role.name =='client'"></vue-footer>
<vue-footer-admin v-if="role.name =='super_admin'"></vue-footer-admin>

</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import {Circle8} from 'vue-loading-spinner'
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
    components : {
      'RotateLoader': ClipLoader
    },
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        transaction : [],
        loading:true,
        dialog:false,
        initials:'',
        valid:true,
        pageCount:1,
        pageSort: {},
        documentList:[],
        totalCount:0,
        page:1,
        itemsPerPage:10,
        selected_job:'',
        search : "",
        selectedItems: [],
        credit : localStorage.getItem('credit') ? localStorage.getItem('credit') : 0,
        dialogDoc:false,
        datatable_options:{},
        offset: 0,
        selected_id : '',
        role: JSON.parse(localStorage.getItem('role')),
        limit: 10,
        selected_project:'',
        checkbox: '',
        process_status_dialog : false,
        headers_jobs: [
          { text: 'History', value: 'history',
            sortable: true },
            { text: 'Amount', value: 'amount',
            sortable: true },
          { text: 'Transaction Id', value: 'transaction_id',
            sortable: true },
          { text: 'Created At', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated At', value: 'updated_at',
            sortable: true,width: '200' },

        ],
        headers_jobs_admin: [
        { text: 'User', value: 'first_name',
        sortable: true },
          { text: 'History', value: 'history',
            sortable: true },
            { text: 'Amount', value: 'amount',
            sortable: true },
          { text: 'Transaction Id', value: 'transaction_id',
            sortable: true },
          { text: 'Created At', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated At', value: 'updated_at',
            sortable: true,width: '200' },

        ],
        projects: [
          
        ],
        users: []
      }
    },
    created () { 
        if(!localStorage.getItem('token'))
            this.$router.push('/login')
        this.getUsers();
    },
    methods:{
      dateFormat(date){
        return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              limit : this.limit,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-credit-history",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
      formatCurrency(amount, locale = 'en-US', currency = 'USD') {
          return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency
          }).format(amount);
        },
        getProcessStatus(id){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            axios({
              method: "GET",
               
              url: "/api/get-process-status/"+id,
              header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.transaction = data.data.value[0];
                console.log(this.transaction);
                this.process_status_dialog = true;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        
        

    },
    watch: {
            datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getUsers()
                },
                deep: true,
            },
        },
    
}
</script>

