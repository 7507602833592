<template>
    <v-app class="bg-white">
    <vue-header-no-login></vue-header-no-login>
    <v-main class="auth-pages mt-10">
        <v-row  style="margin-top: 100px;margin-right: 0px;">
        <v-col cols="12" md="12" lg="12">
          <v-container class="pb-0 mt-10 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
            <div class="privacy-policy">
    <h1>Privacy Policy for PASS Profile LLC</h1>
    <p>Last Updated: 6/10/2024</p>
    
    <h2>Introduction:</h2>
    <p>We are committed to protecting the privacy and security of our users' personal information. This privacy policy outlines the types of information we collect, how it is used, and the steps we take to safeguard it.</p>
    
    <h2>Information Collection:</h2>
    <p>We collect information that you provide directly to us, including but not limited to:</p>
    <ul>
      <li>Personal details such as name and email address when you participate in our surveys or sign up for our services.</li>
      <li>Responses to survey questions which may include personal preferences and opinions.</li>
    </ul>
    
    <h2>Use of Information:</h2>
    <p>The information we collect is used for the following purposes:</p>
    <ul>
      <li>To provide personalized feedback and coaching based on your survey responses.</li>
      <li>To send updates, information, and promotional content related to PASS Profile LLC.</li>
      <li>To improve our services and develop new offerings.</li>
    </ul>
    
    <h2>Data Sharing and Disclosure:</h2>
    <p>We do not share or disclose your personal information to third parties, except as described in this policy. We may share information:</p>
    <ul>
      <li>With service providers who perform services on our behalf.</li>
      <li>If required by law or to protect the rights and safety of PASS Profile LLC and its users.</li>
    </ul>
    
    <h2>Data Security:</h2>
    <p>We implement appropriate technical and organizational measures to protect the security of your personal information. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>
    
    <h2>Your Rights:</h2>
    <p>You have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you. To exercise these rights, please contact us at info@pass-profile.com.</p>
    
    <h2>Changes to This Policy:</h2>
    <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
    
    <h2>Contact Us:</h2>
    <p>If you have any questions about this privacy policy, please contact us at info@pass-profile.com.</p>
  </div>
            </v-container>
            </v-col>
            </v-row>
</v-main>
</v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>