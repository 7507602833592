<template>
  <v-app class="bg-white">
    <!-- <vue-header-no-login></vue-header-no-login> -->
    <v-main class="auth-pages ">

          <v-container  >
                
                        <div style="text-align: center;margin-top: 50px;">
                        <!-- <img v-if="!company && loadingFlag" :height="this.$vuetify.breakpoint.xs ? 100 : 250" class="image-logo2" src="@/assets/img/logo.png"> -->
                                  <!-- <img v-if="company && loadingFlag &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo"> -->
                                  
                                  <h2 class=" text-label " style=" font-size: 25px;font-weight: 600;"><i class="fa fa-qrcode" aria-hidden="true" style="color:  #3498db ;"></i>
QR Code Generator</h2>
                          </div>
                          <v-row class="mt-5">
                            <v-col cols="12" md="3" lg="3"></v-col>
                          <v-col cols="12" md="6" lg="6">
                                <div class="card-padding pb-4">
                                  <v-subheader style="padding: 0px;">Select QR Code Type</v-subheader>
                                  <v-select
                                        :items="['Website','VCard']"
                                        solo
                                        dense
                                        v-model="qr_type"
                                      ></v-select>
                                      <v-subheader style="padding: 0px;">Enter {{qr_type}} URL</v-subheader>
                                    <v-text-field
                                      color="#e91e63"
                                      outlined
                                      v-model="qr_link"
                                      solo
                                      dense
                                    >
                                    </v-text-field>
                                  <v-subheader style="padding: 0px;">Enter QR Code Name</v-subheader>
                                    <v-text-field
                                      color="#e91e63"
                                      outlined
                                      v-model="qr_name"
                                      solo
                                      dense
                                    >
                                    </v-text-field>
                                    <v-subheader style="padding: 0px;">Upload logo/image</v-subheader>
                                    <v-file-input
                                    outlined
                                      dense
                                      solo
                                      @change="uploadImage($event)"
                                      accept="image/png, image/jpeg, image/bmp"
                                    ></v-file-input>

                                    <Cropper
                                          ref="cropper"
                                          v-if="image"
                                          @change="cropImage"
                                          class="upload-example-cropper mb-2"
                                          :src="image"
                                          :stencil-component="$options.components.Stencil"
                                        />
                                        <!-- <div class="button-wrapper">
                                          <span class="button" @click="$refs.file.click()">
                                            <input
                                              type="file"
                                              ref="file"
                                              @change="uploadImage($event)"
                                              accept="image/*"
                                            />
                                            Upload image
                                          </span>
                                          <span class="button" @click="cropImage">Crop image</span>
                                        </div> -->
                                        <!-- <span class="button" @click="cropImage">Crop image</span> -->
                                    <v-row>
                                      <v-col cols="5" md="3"><v-subheader style="padding: 0px;">QR Code Color</v-subheader></v-col>
                                      <v-col cols="7" md="9">
                                    <v-text-field v-model="color"  class="ma-0 pa-0" solo label="QR Code Color">
                                      <template v-slot:append>
                                        <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                          <template v-slot:activator="{ on }">
                                            <div :style="swatchStyle" v-on="on" />
                                          </template>
                                          <v-card>
                                            <v-card-text class="pa-0">
                                              <v-color-picker v-model="color" flat />
                                            </v-card-text>
                                          </v-card>
                                        </v-menu>
                                      </template>
                                    </v-text-field>
                                    </v-col>
                                    </v-row>
                                    <v-subheader style="padding: 0px;">Image Size</v-subheader>

                                      <v-card-text>
                                        <v-slider
                                          v-model="qr_image_size"
                                          color="#3498db"
                                          max="10"
                                          value="3"
                                        ></v-slider>
                                      </v-card-text>
                                      <v-subheader style="padding: 0px;">QR Size</v-subheader>
                                      <v-select
                                        :items="items"
                                        solo
                                        dense
                                        v-model="qr_size"
                                        label="QR Code Size"
                                      ></v-select>
                                  <v-btn
                                    color="#3498db"
                                    
                                    @click="checkForm"
                                                  :loading="loading"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Generate QR Code
                                  </v-btn><v-btn
                                    color="green"
                                    
                                    @click="$router.push('/admin/qr_codes')"
                                                  :loading="loading"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Goto Dashboard
                                  </v-btn>
                                  
                                
                                </div>
                              </v-col>
                              <v-col cols="12" md="3" lg="3" v-if="qr_image"></v-col>
                              <v-col cols="12" align="center" justify="center" class="mb-1">
                                  <img :src="qr_image">
                                  
                                  <!-- <div v-html="qr_image"></div> -->
                                </v-col>
                                <v-col cols="12" align="center" justify="center" v-if="qr_image" class="mb-1">
                                  <v-btn
                                    color="#3498db"
                                    
                                    @click="downloadPNG"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Download QR Code
                                  </v-btn>
                                </v-col>
                              </v-row>
                    </v-container>
        


                    
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import { Cropper } from 'vue-advanced-cropper';
// import 'vue-advanced-cropper/dist/style.css';
import Stencil from "./Stencil";
export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,Cropper,Stencil
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      qr_image_size : 3,
      image : '',
      qr_name : '',
      qr_link : '',
      qr_type: 'Website',
      qr_logo : [],
      dialog: false,
      qr_size : '360x360',
      color: '#000000',
      mask: '!#XXXXXXXX',
      menu: false,
      items : ['360x360','720x720'],
      pageFlag: 'init',
      qr_id : '',
      forgot_flag : false,
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      qr_image : '',
      loading : false,
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    // if (localStorage.getItem('token')) {
    //     if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1)
    //         this.$router.push('/admin/dashboard')
    //     else
    //         this.$router.push('/dashboard')
    // }
},
created: function () {
  this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
  if(this.url)
      this.getCompanyInfo(this.url)
  else
      this.loadingFlag = true;
},
computed: {
    swatchStyle() {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
roundEdges(canvas) {
      var context = canvas.getContext("2d");
      var width = canvas.width;
      var height = canvas.height;
      console.log(width);
      context.globalCompositeOperation = "destination-in";
      context.beginPath();
      context.scale(1, height/width);
      context.arc(width / 2, width / 2, width / 2, 0, Math.PI * 2);
      context.closePath();
      context.fill();
      return canvas;
    },
cropImage() {
  // const result = this.$refs.cropper.getResult();
  // const newTab = window.open();
  const { canvas } = this.$refs.cropper.getResult();
  // const newTab = window.open();
      // newTab.document.body.innerHTML = `<img src="${this.roundEdges(canvas).toDataURL()}"></img>`;
  // const { canvas } = this.$refs.cropper[index].getResult();
  //     console.log(canvas);
  this.roundEdges(canvas).toBlob(blob => {
        console.log(blob);
        this.qr_logo = blob
      }, 'image/png')
      // const newTab = window.open();
      // newTab.document.body.innerHTML = `<img src="${result.canvas.toDataURL(
      //   "image/jpeg"
      // )}"></img>`;
    },
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log(this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
downloadPNG() {
      const a = document.createElement('a');
      a.href = this.qr_image;
      a.download = this.qr_id+'.png'; // Change the file name if needed
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
    },
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company,
            company_flag : this.company ? true : false
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Please check your email for change password', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.forgot_flag = false;
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
uploadImage(event) {//console.log(event.target.files);
  // var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (event) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
        };
        console.log(this.image);
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(event);
      }
    },
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
// forgotPass(){
//     this.buttonFlag =true;
//     this.isDark = false;
//     axios({
//           method: "POST",
           
//           url: "/api/auth/forgot-password",
//           data: {
//             email: this.email,
//           }
//         })
//           .then(response => {
//                 this.sendFlag = 1;
//                 Vue.$toast.success('Forgot Password Mail sent', {
//                 position: 'top-right'
//                 });
//                 this.buttonFlag =false;
//                 this.isDark = true;
//                 this.pageFlag = 'login';
//           })
//           .catch(err => {
//             Vue.$toast.error(err, {
//             position: 'top-right'
//             });
//           })
// },
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.qr_name) {
        Vue.$toast.error('QR details is required', {
            position: 'top-right'
        });
        flag = 0;
      }
      
      if (!this.qr_link) {
        Vue.$toast.error('QR link is required', {
            position: 'top-right'
        });
        flag = 0;
      }else{
        var res = this.qr_link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if(res == null){
          Vue.$toast.error('QR link is not valid', {
            position: 'top-right'
        });
          flag = 0;
        }
      }
      
      
      if (flag == 1) {
        this.loading = true
        const data = new FormData();
        // const { canvas } = this.$refs.cropper.getResult();
        // this.roundEdges(canvas).toBlob(blob => {
        //         console.log(blob);
        //         this.qr_logo = blob
        //       }, 'image/png')
      data.append('qr_logo', this.qr_logo);
      data.append('qr_name', this.qr_name);
      data.append('qr_size', this.qr_size);
      data.append('qr_logo_size', this.qr_image_size);
      data.append('qr_color', this.color);
      data.append('qr_type', this.qr_type);
      data.append('qr_link', this.qr_link);

      if(this.qr_id)
        data.append('id', this.qr_id);
        axios({
          method: "POST",
           
          url: "/api/auth/generate-qr",
          data: data,
          responseType: 'blob'
        })
          .then(response => {
            this.qr_image = response.data;
            let reader = new FileReader();
              reader.readAsDataURL(response.data); 
              reader.onload = () => {
                  this.qr_image = reader.result;
              }
              this.qr_id = response.headers['content-type'];
            this.loading = false
            
            
          })
          .catch(err => {
            if(err.response.data.errors.qr_logo)
                Vue.$toast.error('Invalid file format/Invalid file size(5Mb Max)', {
                position: 'top-right'
                });
          });
      } else {

      }
    }
  }
    
};
</script>
<style>
  /* .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login-new .v-icon{
    color:  #3498db !important;
}
.input-login-new .v-label{
  color:  #3498db !important;
}
.input-login-new #input-6{
  color:  #3498db !important;
}
.input-login-new #input-10{
  color:  #3498db !important;
}
.new-login-left {
  height: 100%;
  display: table;
  position: relative;
}
.new-login-left-box {
  display: table-cell;
  vertical-align: middle;
  height: 770px;
}
.new-login-left-form {
  max-width: 550px;
  width: 90%;
  margin: 30px auto 0;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.text-label{
  font-family: Arial,Helvetica,sans-serif!important;color: rgb(123, 128, 154) !important;font-weight: 400;
}
.default-color-code{
  color:  #3498db !important;
}
.score-header-1{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: "Poppins", Sans-serif;
}
.score-header-2{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.e-hosted-video video {
  max-width: 100%;
width: 100%;
margin: 0;
line-height: 1;
border: none;
} */



</style>