// steps.js

export const steps = [
    {
        "title":"Step 0",
        "fields":[
           {
              "name":"",
              "label":"Speaking/Communication Style Questionnaire",
              "type":"h5",
              "required":true,
              "md":12,
              "class":"font-weight-bolder text-h5 text-typo mb-0"
           },
           {
              "name":"",
              "label":"© 2024 PASS Profile, LLC. All rights reserved.",
              "type":"p",
              "required":true,
              "md":12,
              "class":"mb-0 text-sm text-body font-weight-light mt-2"
           },
           {
              "name":"",
              "label":"This questionnaire/survey, including its content, format, and methodology, is the intellectual property of PASS Profile, LLC. Unauthorized use, reproduction, distribution, or modification of this questionnaire/survey, in whole or in part, without express written permission from PASS Profile, LLC is strictly prohibited.",
              "type":"p",
              "required":true,
              "md":12,
              "class":"mt-5"
           },
           {
              "name":"",
              "label":"This questionnaire/survey developed by PASS Profile, LLC is a proprietary tool and constitutes a <strong>trade secret.</strong> It encompasses unique and confidential methodologies and processes that provide competitive business advantages. As such, it is protected under applicable trade secret laws. Any disclosure, dissemination, or unauthorized use of this material, or any part thereof, is strictly prohibited and may be subject to legal action.",
              "type":"p",
              "required":true,
              "md":12,
              "class":""
           },
           {
              "name":"",
              "label":"<strong>Email Usage Statement</strong>",
              "type":"p",
              "required":true,
              "md":12,
              "class":""
           },
           {
              "name":"",
              "label":"`By providing your email address in this survey, you agree to allow PASS Profile, LLC to use your email solely for the purpose of providing you with information and follow-up related to this survey. We respect your privacy and assure you that your email will be used exclusively by Ted's Voice Academy for the aforementioned purposes and will not be shared with any third parties. Your email will be handled in accordance with our <a target=\"_blank\" class=\"link\" rel=\"noopener noreferrer nofollow\" href=\"https://docs.google.com/document/d/1J90J1Y1OOMc1Y9NUK6u_YQxhL_VL19CmoWHekStHDko/edit?usp=sharing\"><em>privacy policy</em></a> and applicable data protection regulations.`",
              "type":"p",
              "required":true,
              "md":12,
              "class":""
           },
           {
              "name":"full_name",
              "label":"Full Name",
              "type":"text",
              "required":true,
              "md":6
           },
           {
              "name":"company_name",
              "label":"Company Name or Profession (Optional)",
              "type":"text",
              "md":6
           },
           {
              "name":"email",
              "label":"Email",
              "type":"email",
              "required":true,
              "md":6
           },
           {
              "name":"phone",
              "label":"Phone",
              "type":"tel",
              "required":true,
              "md":6
           },
           {
              "name":"is_agree",
              "label":"I agree to terms & conditions",
              "type":"checkbox",
              "required":true,
              "md":12
           }
        ]
     },
     
     ];
  